import React from 'react'
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'

import Grid from '../GridView'

export const QUERY = gql`
  query contacts {
    contacts {
      id
      name
      job
      phones
      company {
        id
        name
      }
    }
  }
`

const GridView = () => (
  <Query query={QUERY}>
    {({ loading, error, data }) => {
      if (error) return <div>Error :(</div>
      const dataset = {
        columns: [
          { name: 'name', title: 'NOME', sort: 'asc' },
          {
            name: 'company',
            title: 'EMPRESA',
            getCellValue: row => row.company && row.company.name
          },
          { name: 'job', title: 'CARGO' },
          {
            name: 'phones',
            title: 'TELEFONE',
            getCellValue: row => row.phones && row.phones[0].value
          },
          { name: 'id', title: ' ' }
        ],
        extensions: [
          { columnName: 'id', width: 75, align: 'center' },
          { columnName: 'phones', width: 150, align: 'center' }
        ],

        rows: data.contacts
      }
      return <Grid loading={loading} {...dataset} />
    }}
  </Query>
)

export default GridView

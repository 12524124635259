import React from 'react'
import { compose } from 'recompose'

import Snackbar from '@material-ui/core/Snackbar'
import { withStyles } from '@material-ui/core/styles'

import SignIn from '../components/SignIn'
import withFirebase from '../withFirebase'

class SigninPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false
    }
  }

  render() {
    const { classes, history, firebase } = this.props
    return (
      <div className={classes.appFrame}>
        <SignIn
          onSubmit={({ email, password }) => {
            firebase
              .auth()
              .signInWithEmailAndPassword(email, password)
              .catch(console.error)
          }}
        />
      </div>
    )
  }
}

const styles = theme => ({
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: '100vh' // Makes the frame full height.
  },
  card: {
    minWidth: 275,
    margin: 'auto'
  }
})

export default compose(
  withFirebase,
  withStyles(styles, { withTheme: true })
)(SigninPage)

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'

import Header from './Header'
import Sidebar from './Sidebar'

export { default as reducer } from './reducer'

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    backgroundColor: theme.palette.background.default
  },
  main: {
    flex: 1
  }
})

class Page extends React.Component {
  state = {
    open: false
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  }

  handleDrawer = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { loading, classes, children, title } = this.props

    return (
      <React.Fragment>
        <LinearProgress
          variant={loading ? 'indeterminate' : 'determinate'}
          value={100}
        />
        <Header handleDrawer={this.handleDrawer} title={title} />
        <div className={classes.container}>
          <Sidebar open={this.state.open} />
          <main className={classes.main}>{children}</main>
        </div>
      </React.Fragment>
    )
  }
}

export default compose(
  connect(state => ({
    loading: state.app.loading
  })),
  withStyles(styles, { withTheme: true })
)(Page)

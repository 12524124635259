import React from 'react'
import { Query, Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'

import { omit, omitBy, remove, isNull } from 'lodash'
import FormView from './FormView'
import { QUERY } from './GridView'
import { omitDeep } from '../ReduxForm'

const GET = gql`
  query bond($id: ID!) {
    bond(id: $id) {
      id
      code
      name
      type
      status
      issueNumber
      range
      issuedValue
      issuedAmount
      issueDate
      dueDate
      issuer {
        id
      }
      manager {
        id
      }
      keeper {
        id
      }
      owner {
        id
      }
    }
  }
`

const UPDATE = gql`
  mutation updateBond($id: ID!, $data: BondInput!) {
    updateBond(id: $id, data: $data) {
      id
      code
      name
      type
      status
      issueNumber
      range
      issuedValue
      issuedAmount
      issueDate
      dueDate
      issuer {
        id
      }
      manager {
        id
      }
      keeper {
        id
      }
      owner {
        id
      }
    }
  }
`
const DELETE = gql`
  mutation deleteBond($id: ID!) {
    deleteBond(id: $id) {
      id
    }
  }
`

const EditView = ({ history, match }) => (
  <Query query={GET} variables={{ id: match.params.id }}>
    {({ loading, error, data: { bond } }) => {
      if (loading) return <div>Loading...</div>
      if (error) return <div>Error :(</div>
      return (
        <Mutation mutation={UPDATE}>
          {(updateBond, { called, data, error, loading }) => {
            return (
              <Mutation
                mutation={DELETE}
                update={(cache, { data: { deleteBond } }) => {
                  const { bonds } = cache.readQuery({
                    query: QUERY
                  })
                  remove(bonds, deleteBond)
                  cache.writeQuery({
                    query: QUERY,
                    data: { bonds }
                  })
                }}>
                {(deleteBond, { called, data, error, loading }) => {
                  return (
                    <FormView
                      initialValues={omit(
                        omitBy(omitDeep(bond, '__typename'), isNull),
                        'id'
                      )}
                      onCancel={() => history.goBack()}
                      onDelete={() =>
                        deleteBond({
                          variables: { id: match.params.id }
                        })
                          .then(resp => {
                            history.replace('/bonds')
                          })
                          .catch(error => console.log(error))
                      }
                      onSubmit={data => {
                        console.log(data)
                        return updateBond({
                          variables: {
                            id: match.params.id,
                            data: omitDeep(data, '__typename')
                          }
                        }).then(resp => {
                          history.replace('/bonds')
                        })
                      }}
                    />
                  )
                }}
              </Mutation>
            )
          }}
        </Mutation>
      )
    }}
  </Query>
)

export default EditView

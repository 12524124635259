import React from 'react'

import {
  Template,
  TemplatePlaceholder,
  Plugin,
  TemplateConnector
} from '@devexpress/dx-react-core'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'

export default function FilterToggle(props) {
  const { toggleFilter } = props

  return (
    <Plugin name="FilterToggle">
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <TemplateConnector>
          {() => (
            <React.Fragment>
              <Tooltip
                title="Toggle filter"
                placement="bottom"
                enterDelay={300}>
                <IconButton onClick={toggleFilter}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          )}
        </TemplateConnector>
      </Template>
    </Plugin>
  )
}

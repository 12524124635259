import React from 'react'
import PropTypes from 'prop-types'
import { compose, withProps, withState } from 'recompose'
import { withRouter } from 'react-router'
import { graphql, Query } from 'react-apollo'
import { gql } from 'apollo-boost'
import { filter } from 'lodash'
import { Helmet } from 'react-helmet'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'

import {
  GroupingState,
  IntegratedGrouping,
  RowDetailState
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  TableRowDetail
} from '@devexpress/dx-react-grid-material-ui'

import { TableDetailToggleCell } from '../GridView/TableDetailToggleCell'

import ReferenceTabs from './ReferenceTabs'
import TitleItem from './TitleItem'
import { GET } from './query'

const GroupGridView = ({
  classes,
  match: {
    params: { id }
  },
  bond,
  current,
  tabs = [],
  inventories = [],
  adding,
  toggleAdd,
  expandedRowIds,
  changeExpandedDetails
}) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{bond ? bond.name : ''}</title>
      </Helmet>

      <Paper classes={{ root: classes.paper }}>
        <ReferenceTabs tabs={tabs} current={current} />
        {adding && (
          <TitleItem
            bondId={id}
            reference={current}
            inventories={inventories}
            expanded={true}
            onCancel={() => toggleAdd(false)}
          />
        )}
        {!adding && (
          <TitleList
            bondId={id}
            reference={current}
            inventories={inventories}
            expandedRowIds={expandedRowIds}
            changeExpandedDetails={changeExpandedDetails}
          />
        )}
        <Button
          variant="fab"
          color="primary"
          aria-label="add"
          onClick={() => toggleAdd(true)}
          className={classes.fab}>
          <AddIcon />
        </Button>
      </Paper>
    </React.Fragment>
  )
}

GroupGridView.propTypes = {
  classes: PropTypes.object.isRequired
}

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
    maxWidth: 1024
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  }
})

const TitleList = ({
  bondId,
  reference,
  expandedRowIds,
  inventories,
  changeExpandedDetails
}) => (
  <Query query={GET} variables={{ where: { bond: { id: bondId } } }}>
    {({ loading, error, data }) => {
      if (loading) return <div>Loading...</div>
      if (error) return <div>Error :(</div>
      const rows = filter(data.documents, { reference })
      const columns = [
        { name: 'inventory', title: 'Inventário' },
        { name: 'title', title: 'Título' },
        { name: 'issueDate', title: 'Data do Documento' },
        {
          name: 'pending',
          title: 'Pendências',
          getCellValue: row => {
            const total = row.registers.length
            const done = filter(row.registers, { done: true }).length
            return total - done
          }
        }
      ]
      return (
        <Grid rows={rows} columns={columns}>
          <GroupingState grouping={[{ columnName: 'inventory' }]} />
          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={expandedRowIds =>
              changeExpandedDetails(expandedRowIds)
            }
          />
          <IntegratedGrouping />
          <Table
            columnExtensions={[
              { columnName: 'pending', align: 'center' },
              { columnName: 'issueDate', align: 'center' }
            ]}
          />
          <TableHeaderRow />
          <TableRowDetail
            contentComponent={({ row }) => (
              <TitleItem
                bondId={bondId}
                reference={reference}
                inventories={inventories}
                initialValues={row}
                onCancel={() => changeExpandedDetails([])}
              />
            )}
            toggleCellComponent={TableDetailToggleCell}
          />
          <TableGroupRow />
        </Grid>
      )
    }}
  </Query>
)

export default compose(
  withRouter,
  graphql(
    gql`
      query bond($id: ID!) {
        bond(id: $id) {
          id
          name
          type
        }
      }
    `,
    {
      options: ({ match: { params } }) => ({
        variables: {
          id: params.id
        }
      })
    }
  ),
  withState('adding', 'toggleAdd', false),
  withState('expandedRowIds', 'changeExpandedDetails', []),
  withProps(({ location, data: { loading, bond } }) => ({
    loading,
    bond,
    current: location.hash.replace('#', '') || 'ata',
    tabs: (bond && tabs[bond.type]) || [],
    inventories: (bond && inventories[bond.type]) || []
  })),
  withStyles(styles)
)(GroupGridView)

const tabs = {
  DEB: ['ATA', 'Produto', 'Garantias', 'Importante'],
  CRI: ['ATA', 'Produto', 'Garantias', 'Importante'],
  CRA: ['ATA', 'Produto', 'Garantias', 'Importante'],
  NPC: ['ATA', 'Produto', 'Garantias', 'Importante'],
  LFS: ['ATA', 'Produto', 'Garantias', 'Importante']
}

const inventories = {
  NPC: {
    ata: ['EMISSORA', 'GARANTIDORA', 'ASSEMBLEIAS'],
    produto: ['NOTA COMERCIAL'],
    garantias: [
      'PENHOR',
      'QUOTAS, AÇÕES E MOVEIS',
      'IMOVEIS',
      'LIVRO DE REGISTROS',
      'CONTRATO SOCIAL',
      'EMBARCAÇÕES',
      'DIREITOS CREDITORIOS',
      'CONTRATO DE BANCO DEPOSITÁRIO',
      'FIANÇA',
      'HIPOTECA',
      'GARANTIAS ESTRANGEIRAS',
      'COMPROMISSOS DE CAPITALIZAÇÃO',
      'PRESTAÇÃO DE SERVIÇOS',
      'COMPARTILHAMENTO DE GARANTIA'
    ],
    importante: [
      'PROCURAÇÕES',
      'SEGUROS',
      'COMPLETION FINANCEIRO',
      'COMPLETION FISICO',
      'CONVOLAÇÃO',
      'CONTROLES',
      'DIQ',
      'AÇÕES JUDICIAIS',
      'OBRIGAÇÕES EXTRAORDINARIAS'
    ]
  },
  DEB: {
    ata: ['EMISSORA', 'GARANTIDORA', 'ASSEMBLEIAS'],
    produto: ['DEBENTURE'],
    garantias: [
      'PENHOR',
      'QUOTAS, AÇÕES E MOVEIS',
      'IMOVEIS',
      'LIVRO DE REGISTROS',
      'CONTRATO SOCIAL',
      'EMBARCAÇÕES',
      'DIREITOS CREDITORIOS',
      'CONTRATO DE BANCO DEPOSITÁRIO',
      'FIANÇA',
      'HIPOTECA',
      'GARANTIAS ESTRANGEIRAS',
      'COMPROMISSOS DE CAPITALIZAÇÃO',
      'COMPARTILHAMENTO DE GARANTIA'
    ],
    importante: [
      'PROCURAÇÕES',
      'SEGUROS',
      'COMPLETION FINANCEIRO',
      'COMPLETION FISICO',
      'CONVOLAÇÃO',
      'CONTROLES',
      'AÇÕES JUDICIAIS',
      'OBRIGAÇÕES EXTRAORDINARIAS'
    ]
  },
  CRI: {
    ata: ['EMISSORA', 'GARANTIDORA', 'ASSEMBLEIAS'],
    produto: [
      'CRI',
      'LASTRO CCI',
      'EMISSÃO CCI',
      'CESSÃO DE DIREITOS',
      'RECOMPRA CCI',
      'MATRICULAS DE IMOVEIS',
      'OUTROS'
    ],
    garantias: [
      'PENHOR',
      'QUOTAS, AÇÕES E MOVEIS',
      'IMOVEIS',
      'LIVRO DE REGISTROS',
      'CONTRATO SOCIAL',
      'EMBARCAÇÕES',
      'DIREITOS CREDITORIOS',
      'CONTRATO DE BANCO DEPOSITÁRIO',
      'FIANÇA',
      'HIPOTECA',
      'GARANTIAS ESTRANGEIRAS',
      'COMPROMISSOS DE CAPITALIZAÇÃO',
      'COMPARTILHAMENTO DE GARANTIA'
    ],
    importante: [
      'PROCURAÇÕES',
      'SEGUROS',
      'COMPLETION FINANCEIRO',
      'COMPLETION FISICO',
      'CONVOLAÇÃO',
      'CONTROLES ',
      'AÇÕES JUDICIAIS',
      'OBRIGAÇÕES EXTRAORDINARIAS',
      'FUNDOS',
      'AQUISIÇÃO E RECOMPRA',
      'CÁLCULOS',
      'RELATÓRIOS'
    ]
  },
  CRA: {
    ata: ['EMISSORA', 'GARANTIDORA', 'ASSEMBLEIAS'],
    produto: ['CRA', 'LASTRO CRA', 'CESSÃO DE DIREITOS', 'OUTROS'],
    garantias: [
      'PENHOR',
      'QUOTAS, AÇÕES E MOVEIS',
      'IMOVEIS',
      'LIVRO DE REGISTROS',
      'CONTRATO SOCIAL',
      'EMBARCAÇÕES',
      'DIREITOS CREDITORIOS',
      'CONTRATO DE BANCO DEPOSITÁRIO',
      'FIANÇA',
      'HIPOTECA',
      'GARANTIAS ESTRANGEIRAS',
      'COMPROMISSOS DE CAPITALIZAÇÃO',
      'COMPARTILHAMENTO DE GARANTIA'
    ],
    importante: [
      'PROCURAÇÕES',
      'SEGUROS',
      'COMPLETION FINANCEIRO',
      'COMPLETION FISICO',
      'CONVOLAÇÃO',
      'CONTROLES ',
      'AÇÕES JUDICIAIS',
      'OBRIGAÇÕES EXTRAORDINARIAS',
      'FUNDOS',
      'AQUISIÇÃO E RECOMPRA',
      'CÁLCULOS',
      'RELATÓRIOS'
    ]
  },
  LFS: {
    ata: ['EMISSORA', 'GARANTIDORA', 'ASSEMBLEIAS'],
    produto: ['LETRA FINANCEIRA'],
    garantias: [
      'PENHOR',
      'QUOTAS, AÇÕES E MOVEIS',
      'IMOVEIS',
      'LIVRO DE REGISTROS',
      'CONTRATO SOCIAL',
      'EMBARCAÇÕES',
      'DIREITOS CREDITORIOS',
      'CONTRATO DE BANCO DEPOSITÁRIO',
      'FIANÇA/AVAL',
      'HIPOTECA',
      'GARANTIAS ESTRANGEIRAS',
      'COMPROMISSOS DE CAPITALIZAÇÃO',
      'PRESTAÇÃO DE SERVIÇOS'
    ],
    importante: [
      'PROCURAÇÕES',
      'SEGUROS',
      'COMPLETION FINANCEIRO',
      'COMPLETION FISICO',
      'CONVOLAÇÃO',
      'CONTROLES ',
      'AÇÕES JUDICIAIS',
      'OBRIGAÇÕES EXTRAORDINARIAS'
    ]
  }
}

import React from 'react'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'

import TextField from './TextField'

const FormField = props => {
  const { width, options, ...rest } = props
  return (
    <Grid item xs={width || true}>
      {rest.select && (
        <TextField fullWidth {...rest}>
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {!rest.select && <TextField fullWidth {...rest} />}
    </Grid>
  )
}

export default FormField

import React from 'react'
import { Field } from 'redux-form'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import conformToMask from './conformToMask'

const isRequired = value => (value ? undefined : 'Obrigatório')

const renderTextField = ({
  input,
  meta: { touched, error },
  helperText,
  ...custom
}) => {
  // console.log(`renderTextField#${input.name}`, touched, error)
  return (
    <TextField
      fullWidth
      {...input}
      {...custom}
      error={touched && !!error}
      helperText={(touched && !!error && error) || helperText}
    />
  )
}
const FormField = ({ xs, sm, md, lg, xl, ...props }) => {
  const validate = []
  const custom = {}
  if (props.required) validate.push(isRequired)
  switch (props.type) {
    case 'cnpj':
      validate.push(isCNPJ)
      custom.normalize = normalizeCNPJ
      break
    case 'phone':
      custom.normalize = normalizePhone
      break
    case 'cep':
      custom.normalize = normalizeZip
      break
    case 'date':
      custom.InputLabelProps = {
        shrink: true
      }
      break
    default:
  }
  return (
    <Grid item {...{ xs, sm, md, lg, xl }}>
      <Field
        {...props}
        {...custom}
        component={renderTextField}
        validate={validate}
      />
    </Grid>
  )
}

export default FormField

const isCNPJ = value => {
  let cnpj = value.replace(/[^\d]+/g, '')

  // Valida a quantidade de caracteres
  if (cnpj.length !== 14) return 'Deve ter 14 dígitos'

  // Elimina inválidos com todos os caracteres iguais
  if (/^(\d)\1+$/.test(cnpj)) return 'Inválido'

  // Cáculo de validação
  let t = cnpj.length - 2,
    d = cnpj.substring(t),
    d1 = parseInt(d.charAt(0), 10),
    d2 = parseInt(d.charAt(1), 10),
    calc = x => {
      let n = cnpj.substring(0, x),
        y = x - 7,
        value = 0,
        r = 0

      for (let i = x; i >= 1; i--) {
        value += n.charAt(x - i) * y--
        if (y < 2) y = 9
      }

      r = 11 - (value % 11)
      return r > 9 ? 0 : r
    }

  return calc(t) === d1 && calc(t + 1) === d2 ? undefined : 'Inválido'
}

const normalizeZip = value => {
  const mask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
  const formatted = conformToMask(value, mask, { guide: false })
  return formatted.conformedValue
}

const normalizeCNPJ = value => {
  const mask = [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ]
  const formatted = conformToMask(value, mask, { guide: false })
  return formatted.conformedValue
}

const normalizePhone = value => {
  const phoneNumberMask = [
    '(',
    /[1-9]/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d|-/,
    /\d|-/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ]
  const formatted = conformToMask(value, phoneNumberMask, { guide: false })
  // const formatted = value.replace(/^(\d{3})(\d{3})?(\d{4})?.*/, '$1-$2-$3')

  // console.log('#normalizePhone', value, formatted)
  return formatted.conformedValue
}

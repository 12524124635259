import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import './index.css'

// UI.
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Grid from '@material-ui/core/Grid'

import { uniqBy, compact } from 'lodash'

const styles = () => ({
  formControl: {
    marginTop: '12px'
  },
  fullWidth: {
    width: '100%'
  }
})

const renderField = ({
  input,
  label,
  options,
  classes,
  meta: { touched, error, initial },
  creatable,
  ...props
}) => {
  // console.log(`renderField#${input.name}`, touched, error)
  const SelectComponent = creatable ? Select.Creatable : Select
  const defaultOptions = options.map(option => {
    const { value = option, label = option } = option
    return { value, label }
  })
  const selectedValues = input.value
    ? input.value.split(',').map(option => {
        const { value = option, label = option } = option
        return { value, label }
      })
    : []
  const optionList = compact(
    uniqBy(defaultOptions.concat(selectedValues), 'value')
  )
  return (
    <FormControl className={classes.fullWidth}>
      <InputLabel shrink htmlFor={props.name}>
        {label}
      </InputLabel>
      <SelectComponent
        fullWidth
        simpleValue
        {...input}
        {...props}
        id={input.name}
        className={classes.formControl}
        onBlur={() => {
          input.onBlur(input.value)
        }}
        options={optionList}
      />
    </FormControl>
  )
}

const required = value => (value ? undefined : 'Required')

class ReactSelect extends PureComponent {
  render() {
    const { xs, sm, md, lg, xl, ...props } = this.props
    // Renders react-select input with persistent mui label, handles focused state.
    const validate = []
    if (props.required) validate.push(required)
    return (
      <Grid item {...{ xs, sm, md, lg, xl }}>
        <Field {...props} component={renderField} validate={validate} />
      </Grid>
    )
  }
}

ReactSelect.propTypes = {
  classes: PropTypes.object.isRequired
}
ReactSelect.defaultProps = {
  creatable: false,
  options: [{ value: 'none', label: 'None' }]
}

export default withStyles(styles)(ReactSelect)

import React from 'react'
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'

import Grid from '../GridView'

export const QUERY = gql`
  query bonds {
    bonds {
      id
      name
      type
      status
      issueDate
      dueDate
      issuer {
        id
        name
      }
    }
  }
`

const GridView = () => (
  <Query query={QUERY}>
    {({ loading, error, data }) => {
      if (error) return <div>Error :(</div>
      const dataset = {
        columns: [
          { name: 'name', title: 'OPERAÇÃO' },
          {
            name: 'issuer',
            title: 'EMISSOR',
            getCellValue: row => row.issuer && row.issuer.name
          },
          {
            name: 'issueDate',
            title: 'EMISSÃO',
            type: 'date'
          },
          {
            name: 'dueDate',
            title: 'VENCIMENTO',
            type: 'date'
          },
          { name: 'id', title: ' ' }
        ],
        extensions: [
          { columnName: 'id', width: 75, align: 'center' },
          { columnName: 'issueDate', width: 120, align: 'center' },
          { columnName: 'dueDate', width: 120, align: 'center' }
        ],
        rows: data.bonds
      }
      return <Grid nofab loading={loading} {...dataset} />
    }}
  </Query>
)

export default GridView

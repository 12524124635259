import React from 'react'
import { Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'
import { compact } from 'lodash'

import FormView from './FormView'
import { QUERY } from './GridView'

const INSERT = gql`
  mutation createDocument($data: DocumentInput!) {
    createDocument(data: $data) {
      id
      name
      description
      issueDate
      dueDate
      registers
      group
      checklist
      tracking
      bond {
        id
        name
      }
    }
  }
`

const InsertView = ({ history, match }) => (
  <Mutation
    mutation={INSERT}
    update={(cache, { data: { createDocument } }) => {
      const { documents } = cache.readQuery({ query: QUERY })
      cache.writeQuery({
        query: QUERY,
        data: { documents: documents.concat([createDocument]) }
      })
    }}>
    {(createDocument, { called, data, error, loading }) => {
      return (
        <FormView
          onCancel={() => history.goBack()}
          onSubmit={data => {
            const variables = {
              data: {
                ...data,
                registers: compact(data.registers)
              }
            }
            console.log(data, variables)

            return createDocument({ variables }).then(
              ({
                data: {
                  createDocument: { id }
                }
              }) => {
                history.replace(`/documents/${id}`)
              }
            )
          }}
        />
      )
    }}
  </Mutation>
)

export default InsertView

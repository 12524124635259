import React from 'react'
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'

import moment from 'moment'
import { filter } from 'lodash'
import Grid from '../GridView'

const formatedate = date => {
  if (!date) return undefined
  const parsed = moment(date)
  return parsed.isValid() ? parsed.format('YYYY-MM-DD') : 'PENDENTE'
}

export const QUERY = gql`
  query documents {
    documents {
      id
      name
      description
      issueDate
      registers
      group
      checklist
      tracking
      bond {
        id
        name
      }
    }
  }
`

const GridView = ({ match: { params } }) => (
  <Query query={QUERY}>
    {({ loading, error, data }) => {
      if (error) return <div>Error :(</div>
      const dataset = {
        columns: [
          {
            name: 'issueDate',
            title: 'DATA',
            type: 'date'
          },
          { name: 'name', title: 'DESCRIÇÃO' },
          {
            name: 'registers',
            title: 'REGISTRO',
            getCellValue: row =>
              row.registers &&
              row.registers.length > 0 &&
              (row.registers.length === 1
                ? row.registers[0] && row.registers[0].name
                : 'DIVERSOS')
          },
          { name: 'group', title: 'GRUPO' },
          {
            name: 'checklist.pdf',
            title: 'PDF',
            type: 'date',
            getCellValue: row =>
              row.checklist ? formatedate(row.checklist.pdf) : undefined
          },
          {
            name: 'checklist.copy',
            title: 'CÓPIA AUTENTICADA',
            type: 'date',
            getCellValue: row =>
              row.checklist ? formatedate(row.checklist.copy) : undefined
          },
          {
            name: 'checklist.original',
            title: 'VIA ORIGINAL',
            type: 'date',
            getCellValue: row =>
              row.checklist ? formatedate(row.checklist.original) : undefined
          },
          {
            name: 'tracking.expiration',
            title: 'DT. EXPIRAÇÃO',
            type: 'date',
            getCellValue: row => row.tracking && row.tracking.expiration
          },
          {
            name: 'tracking.alert',
            title: 'ALERTA',
            getCellValue: row => (row.tracking ? row.tracking.alert : undefined)
          },
          {
            name: 'tracking.request',
            title: 'DT. SOLICITAÇÃO',
            type: 'date',
            getCellValue: row => row.tracking && row.tracking.request
          },
          {
            name: 'tracking.deadline',
            title: 'DT. LIMITE',
            type: 'date',
            getCellValue: row => row.tracking && row.tracking.deadline
          },
          {
            name: 'tracking.term',
            title: 'PRAZO',
            type: 'number',
            getCellValue: row => (row.tracking ? row.tracking.term : undefined)
          },
          { name: 'id', title: ' ' }
        ],
        extensions: [
          { columnName: 'id', width: 75, align: 'center' },
          { columnName: 'issueDate', width: 110, align: 'center' },
          { columnName: 'registers', width: 150, align: 'left' },
          { columnName: 'group', width: 100, align: 'center' },
          { columnName: 'checklist.pdf', width: 100, align: 'center' },
          { columnName: 'checklist.copy', width: 100, align: 'center' },
          { columnName: 'checklist.original', width: 100, align: 'center' },
          { columnName: 'tracking.expiration', width: 100, align: 'center' },
          { columnName: 'tracking.alert', width: 100, align: 'center' },
          { columnName: 'tracking.request', width: 100, align: 'center' },
          { columnName: 'tracking.deadline', width: 100, align: 'center' },
          { columnName: 'tracking.term', width: 100, align: 'center' }
        ],
        bands: [
          {
            title: 'DOCUMENTOS',
            children: [
              { columnName: 'name' },
              { columnName: 'registers' },
              { columnName: 'group' }
            ]
          },
          {
            title: 'CHECKLIST',
            children: [
              { columnName: 'checklist.pdf' },
              { columnName: 'checklist.copy' },
              { columnName: 'checklist.original' }
            ]
          },
          {
            title: 'ACOMPANHAMENTO',
            children: [
              { columnName: 'tracking.expiration' },
              { columnName: 'tracking.alert' },
              { columnName: 'tracking.request' },
              { columnName: 'tracking.deadline' },
              { columnName: 'tracking.term' }
            ]
          }
        ],
        rows: filter(data.documents, doc => doc.bond.id === params.id)
      }
      return <Grid unsavable loading={loading} {...dataset} />
    }}
  </Query>
)

export default GridView

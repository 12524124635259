import React from 'react'
import PropTypes from 'prop-types'
import { compose, withProps, withState } from 'recompose'
import { withRouter } from 'react-router'
import { Query } from 'react-apollo'
import { filter } from 'lodash'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'

import ReferenceTabs from './ReferenceTabs'
import TitleItem from './TitleItem'
import { GET } from './query'

const FormView = ({
  classes,
  match: {
    params: { id }
  },
  current,
  tabs = [],
  adding,
  toggleAdd
}) => {
  return (
    <Paper classes={{ root: classes.paper }}>
      <ReferenceTabs tabs={tabs} current={current} />
      {adding && (
        <TitleItem
          bondId={id}
          reference={current}
          expanded={true}
          onCancel={() => toggleAdd(false)}
        />
      )}
      {!adding && <TitleList bondId={id} reference={current} />}
      <Button
        variant="fab"
        color="primary"
        aria-label="add"
        onClick={() => toggleAdd(true)}
        className={classes.fab}>
        <AddIcon />
      </Button>
    </Paper>
  )
}

FormView.propTypes = {
  classes: PropTypes.object.isRequired
}

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
    maxWidth: 1024
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  }
})

const TitleList = ({ bondId, reference }) => (
  <Query query={GET} variables={{ where: { bond: { id: bondId } } }}>
    {({ loading, error, data }) => {
      if (loading) return <div>Loading...</div>
      if (error) return <div>Error :(</div>
      return filter(data.documents, { reference }).map(document => (
        <TitleItem
          key={document.id}
          bondId={bondId}
          reference={reference}
          initialValues={document}
        />
      ))
    }}
  </Query>
)

export default compose(
  withRouter,
  withState('adding', 'toggleAdd', false),
  withProps(ownerProps => ({
    current: ownerProps.location.hash.replace('#', '') || 'ata',
    tabs: ['ATA', 'Produto', 'Garantias', 'Importante']
  })),
  withStyles(styles)
)(FormView)

import React from 'react'
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'

import Grid from '../GridView'

export const QUERY = gql`
  query {
    companies {
      id
      name
      businessName
      cnpj
      relationship
      industry
      phones
    }
  }
`

const GridView = () => (
  <Query query={QUERY}>
    {({ loading, error, data }) => {
      console.log(loading, error, data)
      // if (loading) return <div>Loading...</div>
      if (error) return <div>Error :(</div>
      const dataset = {
        columns: [
          { name: 'name', title: 'NOME', sort: 'asc' },
          { name: 'businessName', title: 'NOME FANTASIA' },
          { name: 'cnpj', title: 'CNPJ' },
          { name: 'relationship', title: 'RELAÇÃO' },
          { name: 'industry', title: 'INDÚSTRIA' },
          {
            name: 'phones',
            title: 'TELEFONE',
            getCellValue: row => row.phones && row.phones[0].value
          },
          { name: 'id', title: ' ', width: 180 }
        ],
        extensions: [
          { columnName: 'id', width: 75, align: 'center' },
          { columnName: 'cnpj', width: 150, align: 'center' },
          { columnName: 'phones', width: 150, align: 'center' }
        ],
        rows: data.companies
      }
      return <Grid loading={loading} {...dataset} />
    }}
  </Query>
)

export default GridView

import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'

const TextMaskCustom = props => {
  const { inputRef, ...other } = props

  return <MaskedInput {...other} ref={inputRef} placeholderChar={'\u2000'} />
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
}

export const PhoneInput = props => {
  return (
    <TextMaskCustom
      {...props}
      pattern="\(\d{2}\) \d{4}-\d{4}"
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
    />
  )
}

export const CnpjInput = props => {
  return (
    <TextMaskCustom
      {...props}
      pattern="\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}"
      mask={[
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/
      ]}
    />
  )
}

export default TextMaskCustom

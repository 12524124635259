import React from 'react'
import { FieldArray } from 'redux-form'
import { lifecycle } from 'recompose'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import RegisterItem from './RegisterItem'

const renderFields = (member, index, fields) => {
  const AddAction = () => (
    <IconButton
      color="secondary"
      aria-label="adicionar"
      onClick={() => fields.push()}>
      <Icon>add_circle</Icon>
    </IconButton>
  )
  const RemoveAction = () => (
    <IconButton
      color="secondary"
      aria-label="adicionar"
      onClick={() => fields.remove(index)}>
      <Icon>remove_circle</Icon>
    </IconButton>
  )
  return (
    <RegisterItem key={index} name={member}>
      {index > 0 ? <RemoveAction /> : <AddAction />}
    </RegisterItem>
  )
}

const renderMembers = lifecycle({
  componentDidMount() {
    this.props.fields.length === 0 && this.props.fields.push()
  }
})(({ fields }) => <React.Fragment>{fields.map(renderFields)}</React.Fragment>)

const RegisterList = props => (
  <FieldArray {...props} component={renderMembers} />
)

export default RegisterList

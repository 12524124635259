import React from 'react'
import { Field } from 'redux-form'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

const handleChange = handler => ({ target: { files } }) =>
  handler(files.length ? { file: files[0], name: files[0].name } : {})

const renderField = ({
  input: { onChange, onBlur, value: omitValue, ...inputProps },
  meta: omitMeta,
  ...props
}) => (
  <label>
    <input
      id={inputProps.name}
      type="file"
      accept="application/pdf"
      style={{ display: 'none' }}
      onChange={handleChange(onChange)}
      onBlur={handleChange(onBlur)}
      {...inputProps}
      {...props}
    />
    <IconButton color="secondary" component="span">
      <Icon>cloud_upload</Icon>
    </IconButton>
  </label>
)

const FileInput = props => <Field {...props} component={renderField} />

export default FileInput

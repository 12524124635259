import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Page from '../components/Page'
import { GridView, InsertView, EditView } from '../components/Contact'

const CompanyPage = ({ match, title }) => {
  return (
    <Page title={title}>
      <Switch>
        <Route path={`${match.path}/new`} component={InsertView} />
        <Route path={`${match.path}/:id`} component={EditView} />
        <Route path={`${match.path}/`} component={GridView} />
      </Switch>
    </Page>
  )
}

export default CompanyPage

import React from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import TextField from '../Form/TextField'

import _ from 'lodash'

const SignIn = ({ onSubmit }) => {
  const submit = event => {
    event.preventDefault()
    if (event.target.reportValidity()) {
      /* global FormData */
      const formData = new FormData(event.target)
      const dataset = {}
      formData.forEach((v, k) => {
        _.set(dataset, k, v)
      })
      onSubmit(dataset)
    }
  }

  return (
    <Card style={{ maxWidth: 400, margin: 'auto' }}>
      <form autoComplete="off" onSubmit={submit}>
        <CardContent>
          <TextField
            required
            fullWidth
            type="email"
            label="Email"
            name="email"
          />
          <TextField
            required
            fullWidth
            type="password"
            label="Senha"
            name="password"
          />
        </CardContent>
        <CardActions>
          <Button fullWidth variant="raised" color="primary" type="submit">
            Entrar
          </Button>
        </CardActions>
      </form>
    </Card>
  )
}

export default SignIn

import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import logo from '../assets/ftrustee.png'
import './App.css'
import Page from '../components/Page'

const styles = theme => ({
  title: {
    fontFamily: '"Insigna", "Roboto", "Helvetica", "Arial", sans-serif'
  }
})

const Home = ({ classes, title }) => {
  return (
    <Page title={title}>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <Typography
            variant="headline"
            color="inherit"
            classes={{ root: classes.title }}>
            Controle de Documentação
          </Typography>
        </header>
        <p className="App-intro" />
      </div>
    </Page>
  )
}

export default withStyles(styles, { withTheme: true })(Home)

import React from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'
import { compose, withState } from 'recompose'

import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PowerIcon from '@material-ui/icons/PowerSettingsNew'
import HomeIcon from '@material-ui/icons/Home'
import PersonIcon from '@material-ui/icons/Person'
import LocationCity from '@material-ui/icons/LocationCity'
import Contacts from '@material-ui/icons/Contacts'
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import LibraryBooks from '@material-ui/icons/LibraryBooks'

import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'
import { get } from 'lodash'

import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from '@material-ui/core/styles'
import { dark } from '../../material-ui/theme'

import { AUTH_TOKEN } from '../../constant'
import withFirebase from '../../withFirebase'

const drawerWidth = 240
const styles = theme => ({
  paper: {
    top: '64px !important',
    position: 'sticky !important',
    width: drawerWidth,
    height: 'calc(100vh - 64px) !important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  closed: {
    width: 60,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  listitem: {
    paddingLeft: '16px !important'
  },
  active: {
    backgroundColor: theme.palette.secondary.dark,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '& $primary, & $icon': {
      color: theme.palette.common.white
    }
  }
})

const MenuItem = withState('collapsed', 'handleClick', ({ open }) => !open)(
  ({ classes, path, text, icon, data, open, collapsed, handleClick }) => (
    <React.Fragment>
      <ListItem
        button
        activeClassName={classes.active}
        classes={{ gutters: classes.listitem }}
        component={NavLink}
        onClick={() => handleClick(!collapsed)}
        to={`/${path}`}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {get(data, `me.filters.${path}`) &&
          (open && !collapsed ? (
            <ExpandLess color="action" />
          ) : (
            <ExpandMore color="action" />
          ))}
      </ListItem>
      {get(data, `me.filters.${path}`) && (
        <Collapse in={open && !collapsed} timeout="auto" unmountOnExit>
          <List
            component="nav"
            disablePadding
            classes={{ gutters: classes.listitem }}>
            {Object.keys(data.me.filters[path]).map(slug => {
              return (
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={{
                    pathname: `/${path}`,
                    hash: `#${slug}`,
                    state: { filters: data.me.filters[path][slug].filters }
                  }}>
                  <ListItemText primary={data.me.filters[path][slug].name} />
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  )
)

const Menu = ({ firebase, history, classes, open }) => {
  const sidetheme = createMuiTheme(dark)
  return (
    <Query
      query={gql`
        {
          me {
            id
            filters
          }
        }
      `}>
      {({ loading, error, data }) => {
        return (
          <MuiThemeProvider theme={sidetheme}>
            <Drawer
              variant="permanent"
              classes={{
                paper: classNames(classes.paper, !open && classes.closed)
              }}>
              <List component="nav">
                <ListItem
                  button
                  exact
                  activeClassName={classes.active}
                  classes={{ gutters: classes.listitem }}
                  component={NavLink}
                  to="/">
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
                <MenuItem
                  {...{ classes, data, open }}
                  path="companies"
                  text="Empresas"
                  icon={<LocationCity />}
                />
                <MenuItem
                  {...{ classes, data, open }}
                  path="contacts"
                  text="Contatos"
                  icon={<Contacts />}
                />
                <MenuItem
                  {...{ classes, data, open }}
                  path="bonds"
                  text="Operações"
                  icon={<MonetizationOn />}
                />
                <MenuItem
                  {...{ classes, data, open }}
                  path="documents"
                  text="Documentação"
                  icon={<LibraryBooks />}
                />
                <Divider />
                <ListItem button classes={{ gutters: classes.listitem }}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Perfil" />
                </ListItem>
                <ListItem
                  button
                  activeClassName={classes.active}
                  classes={{ gutters: classes.listitem }}
                  onClick={() => {
                    firebase
                      .auth()
                      .signOut()
                      .then(function() {
                        // Sign-out successful.
                        window.token = null
                      })
                      .catch(function(error) {
                        // An error happened.
                      })
                  }}>
                  <ListItemIcon>
                    <PowerIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sair" />
                </ListItem>
              </List>
            </Drawer>
          </MuiThemeProvider>
        )
      }}
    </Query>
  )
}

export default compose(
  withFirebase,
  withRouter,
  withStyles(styles, { withTheme: true })
)(Menu)

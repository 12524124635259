import * as React from 'react'
import * as PropTypes from 'prop-types'
import {
  Getter,
  Template,
  TemplatePlaceholder,
  TemplateConnector,
  Plugin
} from '@devexpress/dx-react-core'
import {
  getColumnFilterConfig,
  tableHeaderRowsWithFilter,
  isFilterTableCell,
  isFilterTableRow,
  getColumnFilterOperations,
  getMessagesFormatter,
  isFilterValueEmpty
} from '@devexpress/dx-grid-core'
import InputAdornment from '@material-ui/core/InputAdornment'

import moment from 'moment'
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from 'react-day-picker/moment'
import 'moment/locale/pt'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

const pluginDependencies = [
  { name: 'FilteringState' },
  { name: 'Table' },
  { name: 'DataTypeProvider', optional: true }
]

export class TableFilterRow extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      filterOperations: {}
    }
  }

  render() {
    const {
      rowHeight,
      showFilterSelector,
      cellComponent: FilterCell,
      rowComponent: FilterRow,
      filterSelectorComponent: FilterSelector,
      iconComponent,
      editorComponent: EditorComponent,
      messages
    } = this.props

    const getMessage = getMessagesFormatter(messages)

    const tableHeaderRowsComputed = ({ tableHeaderRows }) =>
      tableHeaderRowsWithFilter(tableHeaderRows, rowHeight)

    return (
      <Plugin name="TableFilterRow" dependencies={pluginDependencies}>
        <Getter name="tableHeaderRows" computed={tableHeaderRowsComputed} />
        <Template
          name="tableCell"
          predicate={({ tableRow, tableColumn }) =>
            isFilterTableCell(tableRow, tableColumn)
          }>
          {params => (
            <TemplateConnector>
              {(
                {
                  filters,
                  isColumnFilteringEnabled,
                  getAvailableFilterOperations
                },
                { changeColumnFilter }
              ) => {
                const { filterOperations } = this.state
                const { name: columnName } = params.tableColumn.column
                const filter = getColumnFilterConfig(filters, columnName)
                const onFilter = config =>
                  changeColumnFilter({ columnName, config })
                const columnFilterOperations = getColumnFilterOperations(
                  getAvailableFilterOperations,
                  columnName
                )
                const selectedFilterOperation =
                  filterOperations[columnName] || columnFilterOperations[0]
                const handleFilterOperationChange = value => {
                  this.setState({
                    filterOperations: {
                      ...filterOperations,
                      [columnName]: value
                    }
                  })
                  if (filter && !isFilterValueEmpty(filter.value)) {
                    onFilter({ value: filter.value, operation: value })
                  }
                }
                const handleFilterValueChange = value =>
                  onFilter(
                    !isFilterValueEmpty(value)
                      ? { value, operation: selectedFilterOperation }
                      : null
                  )
                const filteringEnabled = isColumnFilteringEnabled(columnName)
                return (
                  <TemplatePlaceholder
                    name="valueEditor"
                    params={{
                      column: params.tableColumn.column,
                      value: filter ? filter.value : undefined,
                      onValueChange: handleFilterValueChange
                    }}>
                    {content => (
                      <FilterCell
                        {...params}
                        getMessage={getMessage}
                        column={params.tableColumn.column}
                        filter={filter}
                        filteringEnabled={filteringEnabled}
                        onFilter={onFilter}>
                        {content ||
                        params.tableColumn.column.type === 'date' ? (
                          <DayPickerInput
                            overlayComponent={({
                              classNames,
                              children,
                              ...props
                            }) => {
                              return (
                                <div
                                  className={classNames.overlayWrapper}
                                  {...props}>
                                  <div className={classNames.overlay}>
                                    {children}
                                    <div style={{ margin: '0 1rem 1rem 1rem' }}>
                                      <Select
                                        fullWidth
                                        value={
                                          filter ? filter.operation : 'contains'
                                        }
                                        onChange={event => {
                                          handleFilterOperationChange(
                                            event.target.value
                                          )
                                        }}>
                                        <MenuItem value="contains">
                                          na data
                                        </MenuItem>
                                        <MenuItem value="greaterThanOrEqual">
                                          a partir de
                                        </MenuItem>
                                        <MenuItem value="lessThanOrEqual">
                                          até a data
                                        </MenuItem>
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              )
                            }}
                            component={props => (
                              <Input
                                fullWidth
                                value={filter ? filter.value : ''}
                                onChange={e =>
                                  onFilter(
                                    e.target.value
                                      ? {
                                          value: moment(e.target.value).format(
                                            'YYYY-MM-DD'
                                          ),
                                          operation: selectedFilterOperation
                                        }
                                      : null
                                  )
                                }
                                placeholder="Filter..."
                                inputProps={{
                                  ...props,
                                  style: { width: '100%' }
                                }}
                              />
                            )}
                            onDayChange={day =>
                              onFilter(
                                day
                                  ? {
                                      value: moment(day).format('YYYY-MM-DD'),
                                      operation: selectedFilterOperation
                                    }
                                  : null
                              )
                            }
                            format="DD/MM/YYYY"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder="DD/MM/YYYY"
                            dayPickerProps={{
                              locale: 'pt',
                              localeUtils: MomentLocaleUtils
                            }}
                          />
                        ) : (
                          <EditorComponent
                            value={filter ? filter.value : ''}
                            disabled={!filteringEnabled}
                            getMessage={getMessage}
                            onChange={handleFilterValueChange}
                            margin="none"
                            endAdornment={
                              showFilterSelector ? (
                                <InputAdornment position="end">
                                  <FilterSelector
                                    iconComponent={iconComponent}
                                    value={selectedFilterOperation}
                                    availableValues={columnFilterOperations}
                                    onChange={handleFilterOperationChange}
                                    disabled={!filteringEnabled}
                                    getMessage={getMessage}
                                  />
                                </InputAdornment>
                              ) : null
                            }
                          />
                        )}
                      </FilterCell>
                    )}
                  </TemplatePlaceholder>
                )
              }}
            </TemplateConnector>
          )}
        </Template>
        <Template
          name="tableRow"
          predicate={({ tableRow }) => isFilterTableRow(tableRow)}>
          {params => <FilterRow {...params} />}
        </Template>
      </Plugin>
    )
  }
}

TableFilterRow.propTypes = {
  rowHeight: PropTypes.any,
  showFilterSelector: PropTypes.bool,
  messages: PropTypes.object,
  cellComponent: PropTypes.func.isRequired,
  rowComponent: PropTypes.func.isRequired,
  filterSelectorComponent: PropTypes.func.isRequired,
  iconComponent: PropTypes.func.isRequired,
  editorComponent: PropTypes.func.isRequired
}

TableFilterRow.defaultProps = {
  rowHeight: undefined,
  showFilterSelector: false,
  messages: {}
}

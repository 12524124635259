import React from 'react'
import { withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import { compose, withState, lifecycle } from 'recompose'

import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import { Form, TextField, SelectField } from '../ReduxForm'
import { CompanySelect } from '../Company'

import {
  Grid as GridView,
  Table,
  TableHeaderRow,
  PagingPanel,
  DragDropProvider,
  TableColumnReordering
} from '@devexpress/dx-react-grid-material-ui'
import { PagingState, IntegratedPaging } from '@devexpress/dx-react-grid'

import moment from 'moment'
import { max, min, sum, filter } from 'lodash'

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2
})
const AssetsGrid = ({ rows = [] }) => {
  // console.log('AssetsGrid', rows)
  // if (!rows) return null

  const columns = [
    { name: 'code', title: 'Código' },
    { name: 'issueNumber', title: 'Emissão' },
    { name: 'serieNumber', title: 'Série' },
    { name: 'type', title: 'Tipo' },
    {
      name: 'issueDate',
      title: 'Emissão',
      getCellValue: row => row.issueDate && moment(row.issueDate).format('L')
    },
    {
      name: 'dueDate',
      title: 'Vencimento',
      getCellValue: row => row.dueDate && moment(row.dueDate).format('L')
    },
    {
      name: 'amount',
      title: 'Volume',
      getCellValue: row => formatter.format(row.amount)
    }
  ]
  const extensions = [
    { columnName: 'amount', width: 200, align: 'right' },
    { columnName: 'issueNumber', align: 'center' },
    { columnName: 'serieNumber', align: 'center' },
    { columnName: 'type', align: 'center' },
    { columnName: 'issueDate', width: 120, align: 'center' },
    { columnName: 'dueDate', width: 120, align: 'center' }
  ]
  return (
    <GridView rows={rows} columns={columns}>
      <PagingState defaultPageSize={10} />
      <IntegratedPaging />
      <DragDropProvider />
      <Table columnExtensions={extensions} />
      <TableColumnReordering defaultOrder={columns.map(col => col.name)} />
      <TableHeaderRow />
      <PagingPanel pageSizes={[5, 10, 15, 0]} />
    </GridView>
  )
}
const FormTemplate = ({ loading, rows, handleSearch, ...props }) => {
  return (
    <Form {...props}>
      <CompanySelect
        required
        name="issuer.id"
        label="Emissor"
        xs={12}
        sm={6}
        onBlur={handleSearch}
      />
      <SelectField
        required
        sm={2}
        name="type"
        label="Instrumento"
        options={options}
        onBlur={handleSearch}
      />
      <TextField
        required
        xs={4}
        sm={2}
        type="number"
        name="issueNumber"
        label="Número"
        helperText="emissão"
        onBlur={handleSearch}
      />
      <TextField
        required
        xs={4}
        sm={1}
        type="number"
        name="range[0]"
        label="Inicial"
        helperText="série"
        onBlur={handleSearch}
      />
      <TextField
        required
        xs={4}
        sm={1}
        type="number"
        name="range[1]"
        label="Final"
        helperText="série"
        onBlur={handleSearch}
      />

      <TextField required xs={12} sm={7} name="name" label="Nome da Operação" />
      <TextField required sm={3} name="code" label="Código" />
      <SelectField sm={2} name="status" label="Status" options={status} />
      <TextField sm type="date" name="issueDate" label="Data de Emissão" />
      <TextField sm type="date" name="dueDate" label="Data de Vencimento" />
      <TextField sm name="issuedValue" label="Valor Emitido" />
      <TextField
        sm
        type="number"
        name="issuedAmount"
        label="Quantidade Emitida"
      />
      <CompanySelect name="owner.id" label="Mandatário" sm={4} />
      <CompanySelect name="manager.id" label="Coordenador" sm={4} />
      <CompanySelect name="keeper.id" label="Escriturador" sm={4} />
      <TextField multiline rowsMax="4" name="notes" label="Anotações" sm={12} />
      <AssetsGrid rows={rows} />
    </Form>
  )
}

FormTemplate.propTypes = {
  // classes: PropTypes.object.isRequired
}

const FormView = compose(
  reduxForm({ form: 'bond' }),
  withApollo,
  // withState('loading', 'isLoading', false),
  withState('rows', 'setRows', []),
  connect(
    state => {
      const selector = formValueSelector('bond')
      const { issuer, type, issueNumber, range } = selector(
        state,
        'issuer',
        'type',
        'issueNumber',
        'range'
      )
      return { issuer, type, issueNumber, range }
    },
    dispatch => ({
      isLoading: loading =>
        dispatch({
          type: 'APP_LOADING',
          payload: loading
        })
    }),
    ({ issuer, type, issueNumber, range }, { isLoading }, ownProps) => {
      const { client, autofill, setRows } = ownProps

      return Object.assign({}, ownProps, {
        handleSearch: async () => {
          if (!issuer) return

          // console.log(issuer, type, issueNumber, range)
          isLoading(true)
          const { data } = await client.query({
            query: gql`
              query searchAssets($issuerId: ID!) {
                searchAssets(issuerId: $issuerId)
              }
            `,
            variables: {
              issuerId: issuer.id
            }
          })
          let dataset = data.searchAssets
          type && (dataset = filter(dataset, { type }))
          // console.log(x.issueNumber, issueNumber, eq(x.issueNumber, issueNumber));
          issueNumber &&
            (dataset = filter(dataset, x => x.issueNumber == issueNumber))
          range &&
            range[0] &&
            (dataset = filter(dataset, x => x.serieNumber >= range[0]))
          range &&
            range[1] &&
            (dataset = filter(dataset, x => x.serieNumber <= range[1]))
          // console.log(data.searchAssets, dataset, type, issueNumber, range)
          const dueDate = max(dataset.map(x => x.dueDate))
          const issueDate = min(dataset.map(x => x.issueDate))
          const issuedValue = sum(dataset.map(x => x.amount))
          // console.log(dataset, dueDate, issuedValue)
          autofill('dueDate', moment(dueDate).format(moment.HTML5_FMT.DATE))
          autofill('issueDate', moment(issueDate).format(moment.HTML5_FMT.DATE))
          autofill('issuedValue', formatter.format(issuedValue))
          setRows(dataset)
          isLoading(false)
        }
      })
    }
  ),
  lifecycle({
    componentDidMount() {
      this.props.handleSearch()
    }
  })
)(FormTemplate)

export default FormView

const options = [
  {
    value: 'DEB',
    label: 'Debênture'
  },
  {
    value: 'CRI',
    label: 'CRI'
  },
  {
    value: 'CRA',
    label: 'CRA'
  }
]
const status = [
  {
    value: 'default',
    label: 'Default'
  },
  {
    value: 'active',
    label: 'Ativa'
  },
  {
    value: 'deleted',
    label: 'Excluída'
  }
]

import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import { FormField as TextField } from './'

const options = [
  {
    value: 'main',
    label: 'Principal'
  },
  {
    value: 'accounting',
    label: 'Cobrança'
  },
  {
    value: 'other',
    label: 'Outro'
  }
]

class AddressField extends React.Component {
  state = {
    key: (this.props.defaultValue && this.props.defaultValue.length) || 0,
    values: (this.props.defaultValue &&
      this.props.defaultValue.map((item, idx) => ({ ...item, key: idx }))) || [
      { key: 0 }
    ]
  }
  add = () => {
    const key = this.state.key + 1
    this.setState({
      key,
      values: [...this.state.values, { key }]
    })
  }
  remove = key => {
    this.setState({
      values: this.state.values.filter(value => value.key !== key)
    })
  }
  render() {
    const { values } = this.state
    return (
      <Grid item xs={12}>
        {values.map(item => {
          return (
            <Grid key={item.key} container spacing={16}>
              <TextField
                defaultValue={item && item.lines && item.lines[0]}
                name={`address[${item.key}].lines[0]`}
                label="Endereço"
                width={6}
              />
              <TextField
                defaultValue={item && item.city}
                name={`address[${item.key}].city`}
                label="Cidate"
              />
              <TextField
                defaultValue={item && item.state}
                name={`address[${item.key}].state`}
                label="Estado"
                width={1}
                inputProps={{ maxLength: 2 }}
              />
              <TextField
                defaultValue={item && item.contry}
                name={`address[${item.key}].country`}
                label="País"
              />
              <TextField
                defaultValue={item && item.lines && item.lines[1]}
                name={`address[${item.key}].lines[1]`}
                label="Complemento"
                width={6}
              />
              <TextField
                defaultValue={item && item.postcode}
                name={`address[${item.key}].postcode`}
                label="CEP"
                width={2}
              />
              <TextField
                select
                defaultValue={item && item.type}
                options={options}
                name={`address[${item.key}].type`}
                label="Tipo"
              />
              <Grid item>
                {item.key === 0 && (
                  <IconButton
                    color="secondary"
                    aria-label="adicionar"
                    style={{ marginTop: 10 }}
                    onClick={() => this.add({})}>
                    <Icon>add_circle</Icon>
                  </IconButton>
                )}
                {item.key !== 0 && (
                  <IconButton
                    color="secondary"
                    aria-label="adicionar"
                    style={{ marginTop: 10 }}
                    onClick={() => this.remove(item.key)}>
                    <Icon>remove_circle</Icon>
                  </IconButton>
                )}
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    )
  }
}

AddressField.propTypes = {}

export default AddressField

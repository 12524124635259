import React from 'react'
import { Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'

import FormView from './FormView'
import { QUERY } from './GridView'

const INSERT = gql`
  mutation createBond($data: BondInput!) {
    createBond(data: $data) {
      id
      code
      name
      type
      status
      issueNumber
      range
      issuedValue
      issuedAmount
      issueDate
      dueDate
      notes
      issuer {
        id
        name
      }
      manager {
        id
        name
      }
      keeper {
        id
        name
      }
      owner {
        id
        name
      }
    }
  }
`

const InsertView = ({ history, match }) => (
  <Mutation
    mutation={INSERT}
    update={(cache, { data: { createBond } }) => {
      const { bonds } = cache.readQuery({ query: QUERY })
      cache.writeQuery({
        query: QUERY,
        data: { bonds: bonds.concat([createBond]) }
      })
    }}>
    {(createBond, { called, data, error, loading }) => {
      return (
        <FormView
          onCancel={() => history.goBack()}
          onSubmit={data => {
            console.log(data)
            createBond({ variables: { data } })
              .then(resp => {
                history.replace('/bonds')
              })
              .catch(error => console.log(error))
          }}
        />
      )
    }}
  </Mutation>
)

export default InsertView

import React from 'react'
import ReactDOM from 'react-dom'
import Router from './Router'
import registerServiceWorker from './registerServiceWorker'
import moment from 'moment'
import 'moment/locale/pt'
import 'typeface-roboto'
import 'material-design-icons/iconfont/material-icons.css'
import './index.css'

moment.locale('pt-BR')
ReactDOM.render(<Router />, document.getElementById('root'))
registerServiceWorker()

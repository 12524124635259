import React from 'react'
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'

import AutocompleteField from '../ReduxForm/AutocompleteField'

const BondSelect = props => {
  return (
    <Query
      query={gql`
        query {
          bonds {
            id
            name
          }
        }
      `}>
      {({ loading, error, data }) => {
        if (error) return <div>Error :(</div>
        const options =
          data.bonds &&
          data.bonds.map(bond => ({
            value: bond.id,
            label: bond.name
          }))
        return (
          <AutocompleteField
            {...props}
            options={options}
            placeholder={loading ? 'Loading...' : 'Selecione a operação'}
          />
        )
      }}
    </Query>
  )
}

export default BondSelect

const defaultState = {
  loading: false
}

export default function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case '@@redux-form/START_SUBMIT':
      return {
        ...state,
        loading: true
      }
    case '@@redux-form/STOP_SUBMIT':
      return {
        ...state,
        loading: false
      }
    case 'APP_LOADING':
      return {
        ...state,
        loading: payload
      }
    default:
      return state
  }
}

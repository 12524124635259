import { SubmissionError } from 'redux-form'
import { compose, withState, withProps, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import { omitBy, isNull, isEmpty, compact } from 'lodash'

import { omitDeep } from '../ReduxForm'
import withFirebase from '../../withFirebase'

import TitleForm from './TitleForm'
import { GET } from './query'

function validate(values) {
  const errors = {}
  if (isEmpty(compact(values.registers))) {
    throw new SubmissionError({
      _error: 'Informe um registro!'
    })
  }
  values.registers &&
    values.registers.forEach(register => {
      if (register && register.tracking) {
        if (register.tracking.pdf && register.tracking.pdf.required) return
        if (register.tracking.copy && register.tracking.copy.required) return
        if (register.tracking.original && register.tracking.original.required)
          return
      }
      errors._error = 'Registro deve conter ao menos um documento!'
    })

  if (!isEmpty(errors)) {
    throw new SubmissionError(errors)
  }
}

function getHashCode(element) {
  if (
    !element ||
    !element.file ||
    !element.file.file ||
    !element.file.file.name
  )
    return null

  return new Promise(resolve => {
    var md5 = require('md5')
    var reader = new FileReader()
    reader.onload = function(evt) {
      resolve(md5(evt.target.result))
    }
    reader.readAsBinaryString(element.file.file)
  })
}

export default compose(
  withState('expanded', 'handleChange', ({ expanded = true }) => expanded),
  withState('notify', 'toggleNotification', false),
  graphql(
    gql`
      mutation createDocument($data: DocumentInput!) {
        createDocument(data: $data) {
          id
          reference
          inventory
          title
          issueDate
          notes
          tracking
          registers
          bond {
            id
            name
            type
          }
        }
      }
    `,
    {
      name: 'onInsert',
      options: {
        update: (proxy, { data: { createDocument } }) => {
          const data = proxy.readQuery({
            query: GET,
            variables: { where: { bond: { id: createDocument.bond.id } } }
          })
          data.documents.push(createDocument)
          proxy.writeQuery({
            query: GET,
            variables: { where: { bond: { id: createDocument.bond.id } } },
            data
          })
        }
      }
    }
  ),
  graphql(
    gql`
      mutation updateDocument($id: ID!, $data: DocumentInput!) {
        updateDocument(id: $id, data: $data) {
          id
          reference
          inventory
          title
          issueDate
          notes
          tracking
          registers
          bond {
            id
            name
            type
          }
        }
      }
    `,
    { name: 'onUpdate' }
  ),
  withProps(({ initialValues, reference, inventories }) => ({
    form: initialValues ? initialValues.id : 'document',
    inventories: inventories[reference]
  })),
  withFirebase,
  withHandlers({
    onSubmitFail: ({ toggleNotification }) => errors => {
      errors._error && toggleNotification(true)
    },
    onSubmit: ({
      bondId,
      reference,
      onInsert,
      onUpdate,
      handleChange,
      onCancel,
      firebase
    }) => formData => {
      validate(formData)
      const { id, registers, ...values } = formData
      const payload = {
        ...omitBy(omitDeep(values, '__typename'), isNull),
        bond: { id: bondId },
        reference
      }
      // console.log(values)
      const documents = registers.map(async register => {
        // console.log('#register', register)
        const hash = await getHashCode(register)
        if (!hash) return register
        // console.log(hash)
        const path = `docs/${hash}`
        const ref = firebase.storage().ref(path)
        return ref.put(register.file.file).then(snapshot => {
          // console.log('#snapshot', snapshot)
          register.file.hash = hash
          register.file.metadata = snapshot.metadata
          return register
        })
      })
      return Promise.all(documents).then(registers => {
        // console.log(registers)
        payload.registers = registers
        const action = id
          ? onUpdate({ variables: { id, data: payload } })
          : onInsert({ variables: { data: payload } })
        return action.then(onCancel).catch(error => {
          throw new SubmissionError({ _error: error.message })
        })
      })
    }
  })
)(TitleForm)

import * as React from 'react'
import * as PropTypes from 'prop-types'
import { TableFilterRow as TableFilterRowBase } from './TableFilterRowBase'
import { TableFilterRow as TableFilterRowMaterial } from '@devexpress/dx-react-grid-material-ui'

const defaultMessages = {
  filterPlaceholder: 'Filter...',
  contains: 'Contains',
  notContains: 'Does not contain',
  startsWith: 'Starts with',
  endsWith: 'Ends with',
  equal: 'Equals',
  notEqual: 'Does not equal',
  greaterThan: 'Greater than',
  greaterThanOrEqual: 'Greater than or equal to',
  lessThan: 'Less than',
  lessThanOrEqual: 'Less than or equal to'
}

export class TableFilterRow extends React.PureComponent {
  render() {
    const { messages, ...restProps } = this.props

    return (
      <TableFilterRowBase
        cellComponent={TableFilterRowMaterial.Cell}
        rowComponent={TableFilterRowMaterial.Row}
        filterSelectorComponent={TableFilterRowMaterial.FilterSelector}
        iconComponent={TableFilterRowMaterial.Icon}
        editorComponent={TableFilterRowMaterial.Editor}
        messages={{ ...defaultMessages, ...messages }}
        {...restProps}
      />
    )
  }
}

TableFilterRow.Cell = TableFilterRowMaterial.Cell
TableFilterRow.Row = TableFilterRowMaterial.Row
TableFilterRow.Editor = TableFilterRowMaterial.Editor
TableFilterRow.FilterSelector = TableFilterRowMaterial.FilterSelector
TableFilterRow.Icon = TableFilterRowMaterial.Icon

TableFilterRow.propTypes = {
  messages: PropTypes.shape({
    filterPlaceholder: PropTypes.string,
    contains: PropTypes.string,
    notContains: PropTypes.string,
    startsWith: PropTypes.string,
    endsWith: PropTypes.string,
    equal: PropTypes.string,
    notEqual: PropTypes.string,
    greaterThan: PropTypes.string,
    greaterThanOrEqual: PropTypes.string,
    lessThan: PropTypes.string,
    lessThanOrEqual: PropTypes.string
  })
}

TableFilterRow.defaultProps = {
  messages: {}
}

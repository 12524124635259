import React from 'react'
import { Query, Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'

import _ from 'lodash'
import FormView from './FormView'
import { QUERY } from './GridView'

const GET = gql`
  query contact($id: ID!) {
    contact(id: $id) {
      id
      name
      birthday
      job
      phones
      emails
      notes
      address
      company {
        id
        name
      }
    }
  }
`

const UPDATE = gql`
  mutation updateContact($id: ID!, $data: ContactInput!) {
    updateContact(id: $id, data: $data) {
      id
      name
      birthday
      job
      phones
      emails
      notes
      address
      company {
        id
        name
      }
    }
  }
`
const DELETE = gql`
  mutation deleteContact($id: ID!) {
    deleteContact(id: $id) {
      id
    }
  }
`

const EditView = ({ history, match }) => (
  <Query query={GET} variables={{ id: match.params.id }}>
    {({ loading, error, data: { contact } }) => {
      if (loading) return <div>Loading...</div>
      if (error) return <div>Error :(</div>
      return (
        <Mutation mutation={UPDATE}>
          {(updateContact, { called, data, error, loading }) => {
            return (
              <Mutation
                mutation={DELETE}
                update={(cache, { data: { deleteContact } }) => {
                  const { contacts } = cache.readQuery({
                    query: QUERY
                  })
                  _.remove(contacts, deleteContact)
                  cache.writeQuery({
                    query: QUERY,
                    data: { contacts }
                  })
                }}>
                {(deleteContact, { called, data, error, loading }) => {
                  return (
                    <FormView
                      data={contact}
                      onCancel={() => history.goBack()}
                      onDelete={() =>
                        deleteContact({
                          variables: { id: match.params.id }
                        })
                          .then(resp => {
                            history.replace('/contacts')
                          })
                          .catch(error => console.log(error))
                      }
                      onSubmit={data => {
                        console.log(data)
                        updateContact({
                          variables: { id: match.params.id, data }
                        })
                          .then(resp => {
                            history.replace('/contacts')
                          })
                          .catch(error => console.log(error))
                      }}
                    />
                  )
                }}
              </Mutation>
            )
          }}
        </Mutation>
      )
    }}
  </Query>
)

export default EditView

import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import { compose, withState } from 'recompose'
import { Helmet } from 'react-helmet'

import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'

import logo from '../../assets/ftrustee.png'

const styles = theme => ({
  flex: {
    flex: 'auto'
  },
  menuButton: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 2
  },
  logo: {
    fontFamily: '"Insigna", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '2rem'
  }
})

const Title = withState('title', 'setTitle', ({ title }) => title)(
  ({ title, setTitle }) => {
    return (
      <React.Fragment>
        <Helmet onChangeClientState={({ title }) => setTitle(title)} />
        <Typography variant="title" color="inherit">
          {title}
        </Typography>
      </React.Fragment>
    )
  }
)
const Header = ({ location, title, classes, handleDrawer }) => (
  <React.Fragment>
    <AppBar position="static" color="default">
      <Toolbar>
        <img
          src={logo}
          style={{ height: '50px' }}
          className="App-logo"
          alt="logo"
        />
      </Toolbar>
    </AppBar>
    <AppBar position="sticky" color="primary">
      <Toolbar disableGutters>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          onClick={handleDrawer}
          aria-label="open drawer">
          <MenuIcon />
        </IconButton>
        <Title titile={(location.state && location.state.title) || title} />
      </Toolbar>
    </AppBar>
  </React.Fragment>
)

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(Header)

import React from 'react'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'
import { AUTH_TOKEN } from './constant'

// Pass your GraphQL endpoint to uri
const client = new ApolloClient({
  fetchOptions: {
    credentials: 'include'
  },
  request: operation => {
    const token = window.token
    operation.setContext({
      headers: {
        authorization: token
      }
    })
  }
})

// Preconfigured HOC
const withApollo = BaseComponent => props => (
  <ApolloProvider client={client}>
    <BaseComponent {...props} />
  </ApolloProvider>
)

export default withApollo

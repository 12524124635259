import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import { compose, withState, withPropsOnChange } from 'recompose'
import { Link } from 'react-router-dom'
import slugify from 'slugify'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  DragDropProvider,
  TableColumnReordering,
  TableBandHeader,
  TableGroupRow,
  Toolbar,
  SearchPanel,
  TableColumnVisibility,
  ColumnChooser,
  TableRowDetail,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui'

import { TableFilterRow } from './TableFilterRow'

import {
  DataTypeProvider,
  GroupingState,
  IntegratedGrouping,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  FilteringState,
  RowDetailState,
  SelectionState
} from '@devexpress/dx-react-grid'

import { filter, isEqual } from 'lodash'
import moment from 'moment'

import FilterToggle from './FilterToggle'
import FilterSave from './FilterSave'

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  },
  grid: {
    '& tbody tr:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !Important',
      cursor: 'pointer'
    }
  },
  cell: {
    width: '100%',
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit
  },
  input: {
    width: '100%'
  }
})

const EditButton = ({ row, value, match }) => {
  const location = {
    pathname: `/${match.url.split('/')[1]}/${value}`,
    state: { title: row.name }
  }
  return (
    <React.Fragment>
      <Link to={location}>
        <IconButton title="Editar">
          <EditIcon />
        </IconButton>
      </Link>
    </React.Fragment>
  )
}

const EditButtonTypeProvider = props => (
  <DataTypeProvider formatterComponent={withRouter(EditButton)} {...props} />
)

const LoadingState = ({ loading, columnCount }) => (
  <td
    colSpan={columnCount}
    style={{ textAlign: 'center', verticalAlign: 'middle' }}>
    <big>{loading ? <CircularProgress /> : <span>No data</span>}</big>
  </td>
)

const GridView = ({
  history,
  match,
  classes,
  rows,
  columns,
  extensions,
  bands,
  grouping,
  loading,
  isLoading,
  filtersRow,
  toggleFilters,
  filters,
  changeFilters,
  detailComponent,
  onSelect,
  handleSave,
  unsavable,
  nofab
}) => {
  const getRowId = row => row.id
  isLoading(loading)
  return (
    <React.Fragment>
      <Paper className={onSelect && classes.grid}>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState
            defaultSorting={filter(columns, 'sort').map(col => ({
              columnName: col.name,
              direction: col.sort
            }))}
          />
          <IntegratedSorting />
          <SearchState />
          <FilteringState
            columnExtensions={[{ columnName: 'id', filteringEnabled: false }]}
            filters={filters}
            onFiltersChange={filters => {
              console.log(filters)
              changeFilters(filters)
            }}
          />
          <RowDetailState />
          <IntegratedFiltering
            columnExtensions={filter(columns, { type: 'date' }).map(col => ({
              columnName: col.name,
              predicate: (value, filter, row) => {
                if (!filter.value || !filter.value.length) return true
                console.log(value, filter)
                return IntegratedFiltering.defaultPredicate(
                  moment(value),
                  { ...filter, value: moment(filter.value) },
                  row
                )
              }
            }))}
          />
          {grouping && <GroupingState grouping={grouping} />}
          {grouping && <IntegratedGrouping />}
          <SelectionState onSelectionChange={onSelect} />
          <EditButtonTypeProvider for={['id']} />
          <DragDropProvider />
          <DataTypeProvider
            for={filter(columns, { type: 'number' }).map(col => col.name)}
            availableFilterOperations={[
              'equal',
              'notEqual',
              'greaterThan',
              'greaterThanOrEqual',
              'lessThan',
              'lessThanOrEqual'
            ]}
          />
          <DataTypeProvider
            for={filter(columns, { type: 'date' }).map(col => col.name)}
            availableFilterOperations={[
              'contains',
              'greaterThanOrEqual',
              'lessThanOrEqual'
            ]}
            formatterComponent={({ value }) =>
              value && moment(value).isValid()
                ? moment(value).format('L')
                : value || ''
            }
          />
          <VirtualTable
            height={window.innerHeight - 64}
            columnExtensions={extensions}
            noDataCellComponent={() => (
              <LoadingState columnCount={columns.length} loading={loading} />
            )}
          />
          <TableColumnReordering defaultOrder={columns.map(col => col.name)} />
          <TableHeaderRow showSortingControls />
          {filtersRow && <TableFilterRow showFilterSelector />}
          {onSelect && (
            <TableSelection
              selectByRowClick
              highlightRow
              showSelectionColumn={false}
            />
          )}
          {bands && <TableBandHeader columnBands={bands} />}
          {grouping && <TableGroupRow />}
          {detailComponent && (
            <TableRowDetail contentComponent={detailComponent} />
          )}
          <TableColumnVisibility />
          <Toolbar />
          <SearchPanel />
          <FilterToggle toggleFilter={() => toggleFilters(!filtersRow)} />
          <ColumnChooser />
          {!unsavable && (
            <FilterSave
              onSaveClick={({ name }) =>
                handleSave({
                  variables: {
                    name,
                    slug: slugify(name, { lower: true }),
                    path: match.url.split('/')[1],
                    filters
                  }
                })
              }
            />
          )}
        </Grid>
      </Paper>
      {!nofab && (
        <Button
          variant="fab"
          color="primary"
          aria-label="add"
          onClick={() => history.push(`${match.url}/new`)}
          className={classes.fab}>
          <AddIcon />
        </Button>
      )}
    </React.Fragment>
  )
}

GridView.defaultProps = {
  rows: [],
  nofab: false
}

export default compose(
  withStyles(styles),
  withRouter,
  withState('filtersRow', 'toggleFilters', false),
  withState(
    'filters',
    'changeFilters',
    ({ location }) => (location.state && location.state.filters) || []
  ),
  withPropsOnChange(
    (props, nextProps) => {
      // console.log(!isEqual(props.location.state, nextProps.location.state))
      // console.table(props.location)
      // console.table(nextProps.location)
      return !isEqual(props.location.state, nextProps.location.state)
    },
    ownerProps => {
      ownerProps.changeFilters(
        (ownerProps.location.state && ownerProps.location.state.filters) || []
      )
    }
  ),
  graphql(
    gql`
      mutation updateFilters(
        $path: String!
        $slug: String!
        $name: String!
        $filters: [JSON!]
      ) {
        updateFilters(
          path: $path
          slug: $slug
          name: $name
          filters: $filters
        ) {
          id
          filters
        }
      }
    `,
    { name: 'handleSave' }
  ),
  connect(
    null,
    (dispatch, { filters }) => ({
      isLoading: loading =>
        dispatch({
          type: 'APP_LOADING',
          payload: loading
        })
    })
  )
)(GridView)

import React from 'react'
import { compose, withState } from 'recompose'

import {
  Template,
  TemplatePlaceholder,
  Plugin,
  TemplateConnector
} from '@devexpress/dx-react-core'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import SaveIcon from '@material-ui/icons/Save'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const FilterSave = props => {
  const { onSaveClick, handleClick, open, form, setForm } = props

  return (
    <Plugin name="FilterSave">
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <TemplateConnector>
          {() => (
            <React.Fragment>
              <Tooltip title="Save filter" placement="bottom" enterDelay={300}>
                <IconButton onClick={() => handleClick(true)}>
                  <SaveIcon />
                </IconButton>
              </Tooltip>
              <Dialog
                open={open}
                onClose={() => handleClick(false)}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                  Salvar Consulta
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Esta consulta será salva e adicionada a sua lista de
                    consutas personalizadas.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    id="name"
                    label="Nome da Consulta"
                    type="text"
                    defaultValue={form.name}
                    onChange={event => setForm({ name: event.target.value })}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleClick(false)} color="primary">
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => {
                      handleClick(false)
                      onSaveClick(form)
                    }}
                    color="primary">
                    Salvar
                  </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
          )}
        </TemplateConnector>
      </Template>
    </Plugin>
  )
}

export default compose(
  withState('open', 'handleClick', false),
  withState('form', 'setForm', {})
)(FilterSave)

import React from 'react'
import { Field } from 'redux-form'

import Grid from '@material-ui/core/Grid'
import { InlineDatePicker } from 'material-ui-pickers/DatePicker'

const isRequired = value => (value ? undefined : 'Obrigatório')

const renderTextField = ({
  input,
  meta: { touched, error },
  helperText,
  ...custom
}) => {
  // console.log(`renderTextField#${input.name}`, touched, error)
  return (
    <InlineDatePicker
      keyboard
      fullWidth
      {...input}
      {...custom}
      error={touched && !!error}
      helperText={(touched && !!error && error) || helperText}
      format="DD/MM/YYYY"
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      onChange={date => input.onChange(date.format('YYYY-MM-DD'))}
    />
  )
}
const DateField = ({ xs, sm, md, lg, xl, ...props }) => {
  const validate = []
  const custom = {}
  if (props.required) validate.push(isRequired)
  return (
    <Grid item {...{ xs, sm, md, lg, xl }}>
      <Field
        {...props}
        {...custom}
        component={renderTextField}
        validate={validate}
      />
    </Grid>
  )
}

export default DateField

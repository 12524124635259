import React from 'react'
import { FormSection, formValues } from 'redux-form'
import { compose, withProps, withState } from 'recompose'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import { uniq, compact, flattenDeep } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'

import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CustomField from './CustomField'
import { AutocompleteField, FileInput, CheckboxField } from '../ReduxForm'
import withFirebase from '../../withFirebase'

const RegisterItem = ({
  classes,
  loading,
  name,
  registers,
  file,
  url,
  children,
  ...props
}) => {
  // console.log(props, url)

  return (
    <Grid container spacing={16}>
      <AutocompleteField
        sm={11}
        name={`${name}.name`}
        label="Registro"
        creatable
        options={registers}
        placeholder={loading ? 'Loading...' : 'Selecione'}
      />
      <Grid item sm={1}>
        {children}
      </Grid>
      <Grid item sm={12}>
        <FormSection
          container
          spacing={16}
          component={Grid}
          name={`${name}.tracking`}>
          <CustomField sm={4} name="pdf" label="PDF" />
          <CustomField sm={4} name="copy" label="CÓPIA AUTENTICADA" />
          <CustomField sm={4} name="original" label="VIA ORIGINAL" />
        </FormSection>
      </Grid>
      <Grid item sm={12}>
        <FileInput name={`${name}.file`} />
        <a
          href={url}
          download={file}
          target="_blank"
          style={{ margin: '0 10px' }}>
          {file}
        </a>
        <FormControlLabel
          control={
            <CheckboxField
              name={`${name}.done`}
              classes={{
                root: classes.root,
                checked: classes.checked
              }}
            />
          }
          label="PENDÊNCIA FINALIZADA"
        />
      </Grid>
    </Grid>
  )
}

const styles = {
  root: {
    color: green[600],
    '&$checked': {
      color: green[500]
    }
  },
  checked: {}
}

export default compose(
  withStyles(styles),
  graphql(
    gql`
      query {
        documents {
          id
          registers
        }
      }
    `
  ),
  withFirebase,
  formValues(({ name, ...props }) => {
    // console.log(props)
    return {
      file: `${name}.file.name`,
      fullPath: `${name}.file.metadata.fullPath`
    }
  }),
  withState('url', 'setUrl', null),
  withProps(
    ({
      firebase,
      fullPath,
      url,
      setUrl,
      data: { loading, error, documents }
    }) => {
      const options = [
        'JUCEPAR',
        'RTD - São Paulo',
        'RTD - Rio de Janeiro',
        'RTD - Sorocaba'
      ]
      const registers = !documents
        ? options
        : compact(
            uniq(
              options.concat(
                flattenDeep(
                  documents.map(
                    doc => doc.registers && doc.registers.map(r => r && r.name)
                  )
                )
              )
            )
          )
      fullPath &&
        !url &&
        firebase
          .storage()
          .ref(fullPath)
          .getDownloadURL()
          .then(url => {
            setUrl(url)
          })

      return {
        loading,
        registers: registers.sort()
      }
    }
  )
)(RegisterItem)

import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'redux-form'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import { withStyles } from '@material-ui/core/styles'

const FormComponent = ({
  children,
  classes,
  initialized,
  pristine,
  submitting,
  handleSubmit,
  onCancel,
  onDelete
}) => (
  <Paper classes={{ root: classes.paper }}>
    <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={16}>
        {children}
      </Grid>
      <Grid
        container
        spacing={16}
        direction="row"
        alignItems="center"
        justify="flex-end">
        <Grid item>
          <Button color="default" onClick={onCancel}>
            Cancelar
          </Button>
        </Grid>
        {initialized && (
          <Grid item>
            <Button
              variant="raised"
              color="default"
              onClick={onDelete}
              disabled={submitting}>
              <DeleteIcon className={classes.leftIcon} />
              Apagar
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            variant="raised"
            color="primary"
            type="submit"
            disabled={pristine || submitting}>
            <SaveIcon className={classes.leftIcon} />
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Form>
  </Paper>
)

FormComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
    maxWidth: 1024
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
})

export default withStyles(styles)(FormComponent)

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Page from '../Page'
import { GridView } from './'
import InsertView from './InsertView'
import EditView from './EditView'

const BondPage = ({ match, title }) => {
  return (
    <Page title={title}>
      <Switch>
        <Route path={`${match.path}/new`} component={InsertView} />
        <Route path={`${match.path}/:id`} component={EditView} />
        <Route path={`${match.path}/`} component={GridView} />
      </Switch>
    </Page>
  )
}

export default BondPage

import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import createHistory from 'history/createBrowserHistory'
import { compose, lifecycle } from 'recompose'

import withFirebase from './withFirebase'
import withApollo from './withApollo'
import withRedux from './withRedux'
import withTheme from './material-ui'
import { isTokenExpired, getUserId } from './helper/jwtHelper'
import { Helmet } from 'react-helmet'

import Home from './pages/Home'
import SigninPage from './pages/SigninPage'
import { Page as BondPage } from './components/Bond'
import { Page as DocumentPage } from './components/Document'
import { Page as CompanyPage } from './components/Company'
import ContactPage from './pages/ContactPage'

import { StackdriverErrorReporter } from 'stackdriver-errors-js'

window.StackTrace = require('stacktrace-js')
const errorHandler = new StackdriverErrorReporter()
errorHandler.start({
  key: process.env.REACT_APP_FIREBASE_APIKEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID
  // service: '<my-service>', // (optional)
  // version: '<my-service-version>' // (optional)
  // reportUncaughtExceptions: false          // (optional) Set to false to stop reporting unhandled exceptions.
  // reportUnhandledPromiseRejections: false  // (optional) Set to false to stop reporting unhandled promise rejections.
  // disabled: true                           // (optional) Set to true to not report errors when calling report(), this can be used when developping locally.
  // context: {user: 'user1'}                 // (optional) You can set the user later using setUser()
})

export const ProtectedRoute = ({ title, component: Component, ...rest }) => {
  const token = window.token
  const expired = token && isTokenExpired(token)
  if (expired) window.token = null
  return token && !expired ? (
    <Route
      {...rest}
      render={matchProps => (
        <React.Fragment>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <Component title={title} {...matchProps} />
        </React.Fragment>
      )}
    />
  ) : (
    <Redirect to="/signin" />
  )
}

const history = createHistory()
function gtag() {
  window.dataLayer.push(arguments)
}

const send = location => {
  const token = window.token
  const user_id = token && getUserId(token)
  const parameters = {
    page_path: `${location.pathname}${location.search}${location.hash}`
  }
  if (user_id) {
    parameters.user_id = user_id
  }
  gtag('config', 'UA-121450028-1', parameters)
}

send(history.location)
history.listen(location => {
  send(location)
})

const Router = ({ user }) => {
  return (
    <BrowserRouter history={history}>
      {user ? (
        <Switch>
          <Route path="/documents" component={DocumentPage} />
          <Route path="/bonds" component={BondPage} />
          <Route path="/contacts" component={ContactPage} />
          <Route path="/companies" component={CompanyPage} />
          <Route path="/" component={Home} />
        </Switch>
      ) : (
        <Route path="/" component={SigninPage} />
      )}
    </BrowserRouter>
  )
}

export default compose(
  withFirebase,
  lifecycle({
    componentDidMount() {
      const { firebase } = this.props
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          console.log('#onAuthStateChanged', user.toJSON())
          user.getIdToken().then(token => {
            console.log('#getIdToken', token)
            errorHandler.setUser(user.uid)
            window.token = token
            this.setState({ user: user.toJSON() })
          })
        } else {
          // User is signed out.
          window.token = null
          this.setState({ user: null })
        }
      })
    }
  }),
  withRedux,
  withApollo,
  withTheme
)(Router)

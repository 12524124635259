import React from 'react'
import { Query, Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'

import { omit, omitBy, remove, isNull } from 'lodash'
import { omitDeep } from '../ReduxForm'
import FormView from './FormView'
import { QUERY } from './GridView'

const GET = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      cnpj
      name
      businessName
      relationship
      industry
      website
      status
      held
      capital
      notes
      group {
        id
      }
      phones
      address
    }
  }
`

const UPDATE = gql`
  mutation updateCompany($id: ID!, $data: CompanyInput!) {
    updateCompany(id: $id, data: $data) {
      id
      cnpj
      name
      businessName
      relationship
      industry
      website
      status
      held
      capital
      notes
      group {
        id
      }
      phones
      address
    }
  }
`
const DELETE = gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(id: $id) {
      id
    }
  }
`

const EditView = ({ history, match }) => (
  <Query query={GET} variables={{ id: match.params.id }}>
    {({ loading, error, data: { company } }) => {
      if (loading) return <div>Loading...</div>
      if (error) return <div>Error :(</div>
      return (
        <Mutation mutation={UPDATE}>
          {(updateCompany, { called, data, error, loading }) => {
            return (
              <Mutation
                mutation={DELETE}
                update={(cache, { data: { deleteCompany } }) => {
                  const { companies } = cache.readQuery({
                    query: QUERY
                  })
                  remove(companies, deleteCompany)
                  cache.writeQuery({
                    query: QUERY,
                    data: { companies }
                  })
                }}>
                {(deleteCompany, { called, data, error, loading }) => {
                  return (
                    <FormView
                      initialValues={omit(
                        omitBy(omitDeep(company, '__typename'), isNull),
                        'id'
                      )}
                      onCancel={() => history.goBack()}
                      onDelete={() =>
                        deleteCompany({
                          variables: { id: match.params.id }
                        })
                          .then(resp => {
                            history.replace('/companies')
                          })
                          .catch(error => console.log(error))
                      }
                      onSubmit={data => {
                        updateCompany({
                          variables: {
                            id: match.params.id,
                            data: omitDeep(data, '__typename')
                          }
                        })
                          .then(resp => {
                            history.push('/companies')
                          })
                          .catch(error => console.log(error))
                      }}
                    />
                  )
                }}
              </Mutation>
            )
          }}
        </Mutation>
      )
    }}
  </Query>
)

export default EditView

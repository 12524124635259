import React from 'react'
import { Field } from 'redux-form'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

const required = value => (value ? undefined : 'Required')

const renderField = ({
  input,
  options,
  meta: { touched, error },
  placeholder,
  ...custom
}) => {
  // console.log(`renderField#${input.name}`, touched, error)
  return (
    <TextField
      fullWidth
      select
      {...input}
      {...custom}
      error={touched && !!error}
      SelectProps={{ displayEmpty: true }}
      helperText={touched && !!error && error}>
      {placeholder && (
        <MenuItem value="" disabled>
          <span
            style={{
              color: 'currentColor',
              opacity: 0.5
            }}>
            {placeholder}
          </span>
        </MenuItem>
      )}
      {options.map(option => {
        const { value = option.toUpperCase(), label = option } = option
        return (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

const SelectField = ({ xs, sm, md, lg, xl, ...props }) => {
  const validate = []
  if (props.required) validate.push(required)
  return (
    <Grid item {...{ xs, sm, md, lg, xl }}>
      <Field {...props} component={renderField} validate={validate} />
    </Grid>
  )
}

SelectField.defaultProps = {
  options: [{ value: '', label: 'None' }]
}

export default SelectField

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Page from '../Page'
import GridView from './GridView'
import InsertView from './InsertView'
import GroupGridView from './GroupGridView'
import GridDetailView from './GridDetailView'

const DocumentPage = ({ match, title }) => {
  return (
    <Page title={title}>
      <Switch>
        <Route path={`${match.path}/:id`} component={GroupGridView} />
        <Route path={`${match.path}/`} component={GridView} />
      </Switch>
    </Page>
  )
}

export default DocumentPage

import { isEmpty } from 'lodash'

export { default as Form } from './Form'
export { default as TextField } from './TextField'
export { default as SelectField } from './SelectField'
export { default as SwitchField } from './SwitchField'
export { default as CheckboxField } from './CheckboxField'
export { default as PhoneList } from './PhoneList'
export { default as AddressList } from './AddressList'
export { default as AutocompleteField } from './AutocompleteField'
export { default as FileInput } from './FileInput'
export { default as DateField } from './DateField'

export function omitDeep(obj, key) {
  if (isEmpty(obj)) return obj
  if (Array.isArray(obj)) return omitDeepArrayWalk(obj, key)
  const keys = Object.keys(obj)
  const newObj = {}
  keys.forEach(i => {
    if (i !== key) {
      const val = obj[i]
      if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key)
      else if (typeof val === 'object' && val !== null)
        newObj[i] = omitDeep(val, key)
      else newObj[i] = val
    }
  })
  return newObj
}

function omitDeepArrayWalk(arr, key) {
  return arr.map(val => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key)
    else if (typeof val === 'object') return omitDeep(val, key)
    return val
  })
}

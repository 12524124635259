import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'

import {
  get,
  set,
  isEmpty,
  isArray,
  omitBy,
  compact,
  transform,
  isEqual,
  isEqualWith,
  isObject,
  toNumber
} from 'lodash'

const customizer = (objValue, othValue) => {
  if (toNumber(objValue) && toNumber(othValue)) {
    return isEqual(toNumber(objValue), toNumber(othValue))
  }
}

const difference = (object, base) => {
  return transform(object, (result, value, key) => {
    if (!isEqualWith(value, base[key], customizer)) {
      result[key] = value
    }
  })
}

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
    maxWidth: 1024
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
})

const FormView = ({
  children,
  classes,
  data,
  onSubmit,
  onDelete,
  onCancel
}) => {
  const submit = event => {
    event.preventDefault()
    if (event.target.reportValidity()) {
      /* global FormData */
      const formData = new FormData(event.target)
      const dataset = {}
      formData.forEach((v, k) => {
        console.log(k, v, get(data, k))
        if (get(data, k) || v) {
          set(dataset, k, v)
        }
      })
      for (const prop in dataset) {
        if (isArray(dataset[prop])) {
          dataset[prop] = compact(dataset[prop])
        }
      }
      if (data) {
        // is an upadte?
        const diff = difference(dataset, data)
        const post = omitBy(diff, value => isObject(value) && isEmpty(value))
        console.log(data, dataset, diff, post)
        !isEmpty(post) && onSubmit(post)
        return
      }
      onSubmit(dataset)
    }
  }

  return (
    <React.Fragment>
      <Paper classes={{ root: classes.paper }}>
        <form noValidate autoComplete="off" onSubmit={submit}>
          {children}
          <Grid
            container
            spacing={16}
            direction="row"
            alignItems="center"
            justify="flex-end">
            <Grid item>
              <Button color="default" onClick={() => onCancel()}>
                Cancelar
              </Button>
            </Grid>
            {data && (
              <Grid item>
                <Button
                  variant="raised"
                  color="default"
                  onClick={() => onDelete()}>
                  <DeleteIcon className={classes.leftIcon} />
                  Apagar
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button variant="raised" color="primary" type="submit">
                <SaveIcon className={classes.leftIcon} />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </React.Fragment>
  )
}

FormView.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default withStyles(styles)(withRouter(FormView))

import React from 'react'
import { lifecycle } from 'recompose'
import { FieldArray } from 'redux-form'

import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import { TextField, SelectField } from './'

const renderPhones = lifecycle({
  componentDidMount() {
    this.props.fields.length === 0 && this.props.fields.push()
  }
})(({ fields, meta: { error, submitFailed } }) => {
  const options = [
    {
      value: 'main',
      label: 'Principal'
    },
    {
      value: 'accounting',
      label: 'Cobrança'
    },
    {
      value: 'fax',
      label: 'FAX'
    },
    {
      value: 'other',
      label: 'Outro'
    }
  ]

  return (
    <React.Fragment>
      {fields.map((member, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Grid container spacing={16}>
            <TextField
              name={`${member}.value`}
              label="Telefone"
              type="phone"
              xs={6}
            />
            <SelectField
              name={`${member}.type`}
              label="Tipo"
              options={options}
              xs={4}
            />
            <Grid item xs={2}>
              <IconButton
                color="secondary"
                aria-label="adicionar"
                style={{ marginTop: 10 }}
                onClick={() =>
                  index > 0 ? fields.remove(index) : fields.push()
                }>
                <Icon>{index > 0 ? 'remove_circle' : 'add_circle'}</Icon>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </React.Fragment>
  )
})

const PhoneList = () => <FieldArray name="phones" component={renderPhones} />

export default PhoneList

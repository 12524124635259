import React from 'react'
import BaseTextField from '@material-ui/core/TextField'
import { PhoneInput, CnpjInput } from './MaskedInput'

export default class TextField extends React.Component {
  state = {
    error: false,
    value: this.props.defaultValue || ''
  }
  handleChange = event => {
    this.setState({ value: event.target.value })
  }
  handleValidity = event => {
    // console.log(event.target.value)
    this.setState({
      error: event.target.validity && !event.target.validity.valid
    })
  }
  render() {
    const { type, defaultValue, ...rest } = this.props
    let defaults = {
      type,
      name: this.props.name || this.props.id,
      id: this.props.id || this.props.name,
      ...rest
    }
    switch (type) {
      case 'date':
        defaults = {
          ...defaults,
          InputLabelProps: {
            shrink: true
          }
        }
        break
      case 'phone':
        defaults = {
          ...defaults,
          InputProps: {
            inputComponent: PhoneInput
          }
        }
        break
      case 'cnpj':
        defaults = {
          ...defaults,
          InputProps: {
            inputComponent: CnpjInput
          }
        }
        break
      default:
    }
    return (
      <BaseTextField
        {...defaults}
        {...this.state}
        onChange={this.handleChange}
        onBlur={event => {
          this.handleValidity(event)
          event.target.validity &&
            event.target.validity.valid &&
            defaults.onBlur &&
            defaults.onBlur(event)
        }}
      />
    )
  }
}

import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID
}

const app = firebase.initializeApp(config)

const FireContext = React.createContext(app)

// This function takes a component...
export default function withFirebase(Component) {
  // ...and returns another component...
  return function FirebasedComponent(props) {
    // ... and renders the wrapped component with the context firebase!
    // Notice that we pass through any additional props as well
    return (
      <FireContext.Consumer>
        {firebase => {
          const auth = () => firebase.auth()
          const storage = () => firebase.storage()
          return <Component {...props} firebase={{ storage, auth }} />
        }}
      </FireContext.Consumer>
    )
  }
}

import React from 'react'
import { Field } from 'redux-form'
import Checkbox from '@material-ui/core/Checkbox'

const renderField = ({
  input: { value, ...input },
  options,
  meta: { touched, error },
  ...custom
}) => <Checkbox checked={value} {...input} {...custom} />

const CheckboxField = ({ xs, sm, md, lg, xl, ...props }) => (
  <Field {...props} component={renderField} />
)

export default CheckboxField

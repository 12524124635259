import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'
import { compose } from 'recompose'

import {
  FormView as Form,
  FormField as TextField,
  SelectField,
  ListField,
  AddressField
} from '../Form'

const styles = theme => ({
  button: {
    marginTop: 10
  }
})

const CompanySelect = ({ defaultValue }) => {
  const QUERY = gql`
    query {
      companies {
        id
        name
      }
    }
  `

  return (
    <Query query={QUERY}>
      {({ loading, error, data }) => {
        if (error) return <div>Error :(</div>
        const options =
          data.companies &&
          data.companies.map(company => ({
            value: company.id,
            label: company.name
          }))
        return (
          <SelectField
            name="company.id"
            label="Empresa"
            defaultValue={defaultValue}
            placeholder={loading ? 'Loading...' : 'Selecione a empresa'}
            options={options}
            width={6}
          />
        )
      }}
    </Query>
  )
}

const ranges = [
  {
    value: 'main',
    label: 'Principal'
  },
  {
    value: 'accounting',
    label: 'Cobrança'
  },
  {
    value: 'other',
    label: 'Outro'
  }
]

const FormView = ({
  classes,
  data,
  onSubmit,
  onDelete,
  onCancel,
  ...props
}) => {
  return (
    <Form
      data={data}
      onSubmit={onSubmit}
      onDelete={onDelete}
      onCancel={onCancel}>
      <Grid container spacing={16}>
        <TextField
          defaultValue={data && data.name}
          name="name"
          label="Nome"
          width={6}
          required
        />
        <TextField
          defaultValue={data && data.birthday}
          name="birthday"
          label="Data de Nascimento"
          type="date"
          width={6}
        />
        <CompanySelect defaultValue={data && data.company && data.company.id} />
        <TextField defaultValue={data && data.job} name="job" label="Cargo" />
        <TextField
          multiline
          rowsMax="4"
          defaultValue={data && data.notes}
          name="notes"
          label="Anotações"
          width={12}
        />
        <ListField
          name="emails"
          label="Email"
          type="email"
          options={ranges}
          defaultValue={data && data.emails}
        />
        <ListField
          name="phones"
          label="Telefone"
          type="phone"
          options={ranges}
          defaultValue={data && data.phones}
        />
        <AddressField defaultValue={data && data.address} />
      </Grid>
    </Form>
  )
}

FormView.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(withStyles(styles))(FormView)

import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import { FormField as TextField } from './'

class ListField extends React.Component {
  state = {
    key: (this.props.defaultValue && this.props.defaultValue.length) || 0,
    values: (this.props.defaultValue &&
      this.props.defaultValue.map((item, idx) => ({ ...item, key: idx }))) || [
      { key: 0 }
    ]
  }
  add = () => {
    const key = this.state.key + 1
    this.setState({
      key,
      values: [...this.state.values, { key }]
    })
  }
  remove = key => {
    this.setState({
      values: this.state.values.filter(value => value.key !== key)
    })
  }
  render() {
    const { values } = this.state
    const { name, label, type = 'text', options } = this.props
    return (
      <Grid item xs={6}>
        {values.map(item => (
          <Grid key={item.key} container spacing={16}>
            <TextField
              defaultValue={item && item.value}
              name={`${name}[${item.key}].value`}
              label={label}
              type={type}
              width={7}
            />
            <TextField
              select
              defaultValue={item && item.type}
              options={options}
              name={`${name}[${item.key}].type`}
              label="Tipo"
            />
            <Grid item>
              {item.key === 0 && (
                <IconButton
                  color="secondary"
                  aria-label="adicionar"
                  style={{ marginTop: 10 }}
                  onClick={() => this.add({})}>
                  <Icon>add_circle</Icon>
                </IconButton>
              )}
              {item.key !== 0 && (
                <IconButton
                  color="secondary"
                  aria-label="adicionar"
                  style={{ marginTop: 10 }}
                  onClick={() => this.remove(item.key)}>
                  <Icon>remove_circle</Icon>
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    )
  }
}

ListField.propTypes = {
  options: PropTypes.array.isRequired
}

export default ListField

import { gql } from 'apollo-boost'

export const GET = gql`
  query documents($where: JSON) {
    documents(where: $where) {
      id
      reference
      inventory
      title
      issueDate
      notes
      tracking
      registers
      bond {
        id
        name
        type
      }
    }
  }
`

import React from 'react'
import { Field } from 'redux-form'
import Switch from '@material-ui/core/Switch'

const renderField = ({
  input: { value, ...input },
  options,
  meta: { touched, error },
  ...custom
}) => <Switch checked={value} {...input} {...custom} />

const SwitchField = ({ xs, sm, md, lg, xl, ...props }) => (
  <Field {...props} component={renderField} />
)

export default SwitchField

import React from 'react'
import { Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'

import FormView from './FormView'
import { QUERY } from './GridView'

const INSERT = gql`
  mutation createCompany($data: CompanyInput!) {
    createCompany(data: $data) {
      id
      name
      businessName
      cnpj
      relationship
      industry
      phones
      notes
      address
    }
  }
`

const InsertView = ({ history, match }) => (
  <Mutation
    mutation={INSERT}
    update={(cache, { data: { createCompany } }) => {
      const { companies } = cache.readQuery({ query: QUERY })
      cache.writeQuery({
        query: QUERY,
        data: { companies: companies.concat([createCompany]) }
      })
    }}>
    {(createCompany, { called, data, error, loading }) => {
      return (
        <FormView
          onCancel={() => history.goBack()}
          onSubmit={data => {
            console.log(data)
            createCompany({ variables: { data } })
              .then(resp => {
                history.push('/companies')
              })
              .catch(error => console.log(error))
          }}
        />
      )
    }}
  </Mutation>
)

export default InsertView

import React from 'react'
import { lifecycle } from 'recompose'
import { FieldArray } from 'redux-form'

import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import { TextField, SelectField } from './'

const renderAddress = lifecycle({
  componentDidMount() {
    this.props.fields.length === 0 && this.props.fields.push()
  }
})(({ fields, meta: { error, submitFailed } }) => {
  const options = [
    {
      value: 'main',
      label: 'Principal'
    },
    {
      value: 'accounting',
      label: 'Cobrança'
    },
    {
      value: 'other',
      label: 'Outro'
    }
  ]

  return (
    <React.Fragment>
      {fields.map((member, index) => (
        <React.Fragment key={index}>
          <TextField
            name={`${member}.lines[0]`}
            label="Endereço"
            xs={12}
            sm={6}
          />
          <TextField sm xs={12} name={`${member}.city`} label="Cidate" />
          <TextField
            name={`${member}.state`}
            label="Estado"
            sm={1}
            xs={4}
            inputProps={{ maxLength: 2 }}
          />
          <TextField name={`${member}.country`} label="País" />
          <TextField
            name={`${member}.lines[1]`}
            label="Complemento"
            xs={12}
            sm={6}
          />
          <TextField
            name={`${member}.postcode`}
            label="CEP"
            type="cep"
            sm={2}
            xs={5}
          />
          <SelectField
            xs
            name={`${member}.type`}
            label="Tipo"
            options={options}
          />
          <Grid item xs={2}>
            <IconButton
              color="secondary"
              aria-label="adicionar"
              style={{ marginTop: 10 }}
              onClick={() =>
                index > 0 ? fields.remove(index) : fields.push()
              }>
              <Icon>{index > 0 ? 'remove_circle' : 'add_circle'}</Icon>
            </IconButton>
          </Grid>
        </React.Fragment>
      ))}
    </React.Fragment>
  )
})

const AddressList = () => (
  <FieldArray name="address" component={renderAddress} />
)

export default AddressList

import React from 'react'
import { Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'

import FormView from './FormView'
import { QUERY } from './GridView'

const INSERT = gql`
  mutation createContact($data: ContactInput!) {
    createContact(data: $data) {
      id
      name
      birthday
      job
      phones
      emails
      notes
      address
      company {
        id
        name
      }
    }
  }
`

const InsertView = ({ history, match }) => (
  <Mutation
    mutation={INSERT}
    update={(cache, { data: { createContact } }) => {
      const { contacts } = cache.readQuery({ query: QUERY })
      cache.writeQuery({
        query: QUERY,
        data: { contacts: contacts.concat([createContact]) }
      })
    }}>
    {(createContact, { called, data, error, loading }) => {
      return (
        <FormView
          onCancel={() => history.goBack()}
          onSubmit={data => {
            console.log(data)
            createContact({ variables: { data } })
              .then(resp => {
                history.replace('/contacts')
              })
              .catch(error => console.log(error))
          }}
        />
      )
    }}
  </Mutation>
)

export default InsertView

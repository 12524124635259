import React from 'react'
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'

import AutocompleteField from '../ReduxForm/AutocompleteField'

const CompanySelect = props => {
  return (
    <Query
      query={gql`
        query {
          companies {
            id
            name
          }
        }
      `}>
      {({ loading, error, data }) => {
        if (error) return <div>Error :(</div>
        const options =
          data.companies &&
          data.companies.map(company => ({
            value: company.id,
            label: company.name
          }))
        return (
          <AutocompleteField
            {...props}
            options={options}
            placeholder={loading ? 'Loading...' : 'Selecione a empresa'}
          />
        )
      }}
    </Query>
  )
}

export default CompanySelect

/// REF: https://material-ui-next.com/style/color/
import amber from '@material-ui/core/colors/amber'

export const theme = {
  palette: {
    primary: {
      main: '#203765'
    },
    secondary: {
      main: amber[500]
    }
  }
}

export const dark = {
  ...theme,
  palette: {
    type: 'dark'
  }
}
